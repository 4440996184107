import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const PhotoFormWindow = ({
  handleClose,
  open,
  updatePhoto,
  event,
  onAddPhoto,
  index
}) => {
  return (
    <>
      {open === undefined ? null : (
        <Dialog
          open={open}
          keepMounted
          disableEscapeKeyDown
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: "center" }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {/* backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})` */}
          <Box sx={{ backgroundColor: "#ffffff", borderRadius: "25px" }}>
            <DialogTitle sx={{ margin: "2rem" }}>
              <Typography
                sx={{
                  fontFamily: "SegoeUi",
                  margin: "1rem",
                  fontStyle: "italic",
                  fontSize: "1.5rem",
                }}
                textAlign="center"
                color="primary"
              >
                Choose media
              </Typography>
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {event.photos === undefined
                  ? null
                  : event.photos.map((file, indexPhoto) => (
                      <Box
                        key={indexPhoto}
                        sx={{
                          display: "flex",
                          marginBottom: "20px",
                        }}
                      >
                        {/* Hidden input element */}
                        <input
                          type="file"
                          id={`imageInput-${index}-${indexPhoto}`}
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(event) =>
                            {updatePhoto("base64", event.target.files[0], index, indexPhoto);
                            event.target.value = null;}
                          }
                        />
                        {event.photos[indexPhoto] !== undefined && event.photos[indexPhoto].base64 !== "" ? (
                           <img
                           className="inputImg"
                           src={URL.createObjectURL(event.photos[indexPhoto].base64)}
                           alt="uploadedImage"
                           style={{ cursor: "pointer" }}
                           onClick={() =>
                            document.getElementById(`imageInput-${index}-${indexPhoto}`).click()
                          }
                         />
                        ) : (
                          <img
                            className="inputImg"
                            src="assets/icon/Photo-Cutout.svg"
                            alt="placeHolder"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              document.getElementById(`imageInput-${index}-${indexPhoto}`).click()  
                            }
                          />
                        )}
                      </Box>
                    ))}

                {event.photos.length <= 5 ? (
                  <Button onClick={() => onAddPhoto(index)}>
                    <AddIcon color="secondary" sx={{ fontSize: 100 }} />
                  </Button>
                ) : null}
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default PhotoFormWindow;
