//react
import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { userContext } from "../../Context/userContext.js";
import { locationContext } from "../../Context/locationContext.js";
//style
import "./GoogleMap.css";
import { Box } from "@mui/material";
import DialogWindowFullFeature from "../../components/DialogWindow/DialogWindowFullFeature";

const GoogleMapPeople = () => {
  const AnyReactComponent = ({ text, base64 }) => (
    <div
      style={{ color: "red", cursor: "pointer" }}
      className="pinPoint"
      onClick={handleOpenNavFeature}
    >
      <img className="cadre" src="./assets/icon/Cadre.svg" alt="cadre" />
      <img
        className="image"
        src={`data:image/jpeg;base64,${base64}`}
        alt={text}
      />
    </div>
  );
  const AnyReactComponentProfil = ({ text, base64 }) => (
    <div
      style={{ color: "red", cursor: "pointer" }}
      className="pinPoint"
      onClick={handleOpenNavFeature}
    >
      <img
        className="imgPinPoint"
        src="./assets/icon/PinPoint.svg"
        alt="cadre"
      />
      <img
        className="pdp"
        src={`data:image/jpeg;base64,${base64}`}
        alt={text}
      />
    </div>
  );

  const { user } = useContext(userContext);
  const { currentLocation } = useContext(locationContext);
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  // if user undefined load else show

  const [peoples, setPeoples] = useState([]);

  //status: 0-2 [0=online, 1=int. only, 2=invisible]
  const [status, setStatus] = useState("");
  // distance visible en latitude
  const [latDeg, setLatDeg] = useState("100");
  // distance visible en longitude
  const [lngDeg, setLngDeg] = useState("100");
  // 0-1 [0=people, 1=event]
  const [type, setType] = useState("1");
  // interest [all, friendship, love, fun, discovery, party]
  const [int, setInt] = useState("all");
  // 0-2 [0=all, 1=default, 2=custom]
  const [intType, setIntType] = useState("0");

  const defaultProps = {
    center: {
      lat: 41.890214172631346,
      lng: 12.492230897824744,
    },
    zoom: 15,
  };

  useEffect(() => {
    if (user !== null) {
      setStatus(user.user.status);
      peopleProximity();
    }
  }, [user]);

  const peopleProximity = async () => {
    const response = await fetch(
      "https://looking4-api.mobileappslabs.ca/map/getLocations",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: user.token,
          userid: user.user.id,
        },
        body: new URLSearchParams({
          status: status,
          lat: currentLocation.lat,
          lng: currentLocation.lng,
          latDeg: latDeg,
          lngDeg: lngDeg,
          type: type,
          int: int,
          intType: intType,
        }),
      }
    );

    const data = await response.json();
    // console.log(data);
    setPeoples(data.data);
    console.log(peoples);
  };

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    mapRef.current = map;
    setMapReady(true);
    peopleProximity();
    // new Marker({
    //   position: currentLocation,
    //   label: "bob",
    //   map: map,
    // })
  };
  const [mapZoom, setMapZoom] = useState(defaultProps.zoom);
  const mapOnChange = (map) => {
    if (map.zoom !== mapZoom) {
      peopleProximity();
      console.log("map zoom changed");
    }
  };
  const [anchorElFeature, setAnchorElFeature] = useState(null);

  const handleOpenNavFeature = (event) => {
    setAnchorElFeature(event.currentTarget);
  };

  const handleCloseNavFeature = () => {
    setAnchorElFeature(null);
  };
  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <DialogWindowFullFeature
        handleClose={handleCloseNavFeature}
        open={anchorElFeature}
        handleCloseNavFeature
      />
      {peoples !== undefined ? (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: [process.env.REACT_APP_GOOGLE_MAPS_API],
            libraries: ["places"],
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          // onChange={(map) => console.log('Map moved', map)}
          onChange={(map) => mapOnChange(map)}
          options={{
            fullscreenControl: false,
            zoomControl: false,
            centerControl: true,
            mapTypeId: "hybrid",
          }}
          center={currentLocation}
        >
          {/* {console.log(peoples)} */}
          {peoples.map((people, index) => (
            <AnyReactComponent
              key={index}
              lat={people?.lat}
              lng={people?.lng}
              text={people?.name}
              base64={people?.photo}
            />
          ))}

          <AnyReactComponentProfil
            lat={currentLocation.lat}
            lng={currentLocation.lng}
            text={user?.user?.name}
            base64={user?.user?.profile_image}
          />
        </GoogleMapReact>
      ) : (
        <h1>Loading</h1>
      )}
    </Box>
  );
};

export default GoogleMapPeople;
