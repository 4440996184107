import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Button
} from "@mui/material";
import {useState} from "react";
const DescriptionWindow = ({
  handleClose,
  open,
  event,
  index,
  updateEventDescription
}) => {

  const [description, setDescription] = useState(event.description);
  return (
    <>
      {open === undefined ? null : (
        <Dialog
          open={open}
          keepMounted
          // disableEscapeKeyDown
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: "center" }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {/* backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})` */}
          <Box sx={{ backgroundColor: "#ffffff", borderRadius: "25px" }}>
            <DialogTitle sx={{ margin: "1rem" }}>
              <Typography
                sx={{
                  fontFamily: "SegoeUi",
                  margin: "1rem",
                  fontStyle: "italic",
                  fontSize: "1.5rem",
                }}
                textAlign="center"
                color="primary"
              >
                Description
              </Typography>
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flexWrap: "nowrap",
                }}
              >
                      
                       {/* description max 500 charactere */}
                <Box sx={{ minWidth: 400, margin: "1rem" }}>
                
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <TextField
                    id="description"
                    label="Description"
                    multiline
                    rows={8}
                    value={description}
                    placeholder="Description..."
                    // onChange={(e) => updateEvent("description", e.target.value, index)}
                    onChange={(e) => setDescription(e.target.value)}
                    //a revoir
                    autoFocus={!open ? false : true}
                  />
                </FormControl>
                
                </Box>       
                
                <Button
                    sx={{
                      
                      margin:"1rem",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => {handleClose();  updateEventDescription(index, description);}}
                  >
                    <Typography
                      sx={{
                        fontFamily: "SegoeUi",
                        fontSize: "12px",
                        textTransform: "capitalize",
                      }}
                      className="button-cvs"
                      color="white"
                      variant="button"
                    >
                      close
                    </Typography>
                  </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default DescriptionWindow;
