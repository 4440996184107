import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { MuiFileInput } from "mui-file-input";
const ImportWindow = ({ handleClose, open, onInputChange, file, handleImportCsv }) => {
  return (
    <>
      {open === undefined ? null : (
        <Dialog
          open={open}
          keepMounted
          disableEscapeKeyDown
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: "center" }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {/* backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})` */}
          <Box sx={{ backgroundColor: "#ffffff", borderRadius: "25px" }}>
            <DialogTitle sx={{ margin: "2rem" }}>
              <Typography
                sx={{
                  fontFamily: "SegoeUi",
                  margin: "1rem",
                  fontStyle: "italic",
                  fontSize: "1.5rem",
                }}
                textAlign="center"
                color="primary"
              >
                Choose csv file
              </Typography>
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2rem",
                    justifyContent: "center",
                  }}
                >
                  <MuiFileInput
                    inputProps={{ accept: ".csv" }}
                    value={file}
                    onChange={(newFile) => onInputChange(newFile)}
                  />
                  <Button
                    sx={{
                      marginTop: "2rem",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => handleImportCsv(file)}
                  >
                    <Typography
                      sx={{
                        fontFamily: "SegoeUi",
                        fontSize: "12px",
                        textTransform: "capitalize",
                      }}
                      className="button-cvs"
                      color="white"
                      variant="button"
                    >
                      Import
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default ImportWindow;
