import React, { useEffect, useState, useContext } from "react";
import { authContext } from "./authContext";

const userContext = React.createContext({
  statusState: (userStatus) => {},
  user: null,
});

const { Provider } = userContext;

const UserProvider = ({ children }) => {
  // authcontext
  const ctx = useContext(authContext);
  // user
  const [user, setUser] = useState(null);

  // regarder si il y une photo sinon placeholder

  useEffect(() => {
    if (ctx.user !== null) {
      setUser(ctx.user);
    } else {
      setUser(null);
      console.log(ctx.user);
      console.log("failed to set user");
    }
  }, [ctx.user]);

  const statusState = async (userStatus) => {
    //status: 0-2 [0=online, 1=int. only, 2=invisible]
    console.log(userStatus);
    const response = await fetch(
      "https://looking4-api.mobileappslabs.ca/users/setStatus",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: ctx.user.token,
          userid: ctx.user.user.id,
        },
        body: new URLSearchParams({
          status: parseInt(userStatus),
        }),
      }
    );
    //verifier si status code = ok that it
    const data = await response.json();
    // console.log(data);
    // console.log(user);
  };

  return <Provider value={{ user, statusState }}>{children}</Provider>;
};
export { UserProvider, userContext };
