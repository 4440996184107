import {RouterProvider, createBrowserRouter, Navigate} from 'react-router-dom';
import { useContext } from 'react';

import AuthLayout from '../AuthLayout/AuthLayout';
import Events from '../../Pages/Map/Events';
import People from '../../Pages/Map/People';
import Login from '../../Pages/Login/Login';
import Admin from '../../Pages/Admin/Admin';
import Register from '../../Pages/Register/Register';
import DeleteAccount from '../../Pages/DeleteAccount/DeleteAccount';

import { authContext } from '../../Context/authContext';



function App() {
  
  const {user} = useContext(authContext);
  // console.log(user);
  const routes = user !== null
    ? [
      {
        path: "/",
        element:<AuthLayout/>,
        children: [
          {
            index: true,
            element:<People/>,
          },
          {
            path: "Events",
            element:<Events/>,
          },
          {
            path: "Admin",
            element: <Admin/>
          },

        ]
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ] : [
      {
        path: "/",
        element: <Login />,
      },
      {
        path:"Register",
        element: <Register/>
      },
      {
        path:"DeleteAccount",
        element: <DeleteAccount/>
      },
      // {
      //   path:"Test",
      //   element: <Test/>
      // },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ];


  return (
  <RouterProvider router={createBrowserRouter
    (routes)}/>
  );
}

export default App;
