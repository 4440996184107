
import { createTheme } from "@mui/material/styles";

const Colors = {
    primary: "#886BBC", // mauve
    secondary: "#707070", // gris
    pale: "#C3B4DD", // mauve pale
    palePink: "#ffdede",
    paleGreen: "#e7ffd6",
    redDelete: "#c83c3c",
    lightGray: "#f1f2f2",
    ///////////////////
    ///solid colors///
    /////////////////
    green: "#58b100", 
    yellow: "#FFFF00", 
    red: "#FF0000", 
    white: "#FFFFFF",
    black: "#000000",
};

const theme = createTheme({
    palette: {
        primary: {
            main: Colors.primary,
        },
        palePink: {
            main: Colors.palePink,
        },
        paleGreen: {
            main: Colors.paleGreen,
        },
        secondary: {
            main: Colors.secondary,
        },
        redDelete: {
            main: Colors.redDelete,
        },
        lightGray: {
            main: Colors.lightGray,
        },
        pale: {
            main: Colors.pale,
        },
        white: {
            main: Colors.white,
        },
        black: {
            main: Colors.black,
        },
        green: {
            main: Colors.green,
        },
        yellow: {
            main: Colors.yellow,
        },
        red: {
            main: Colors.red,
        },
    },
     //*************************************************** */
    //overwrite Breaking points
    breakpoints: {
        values: {
          xs: 0,
          smd:376,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
     //*************************************************** */
    //overwrite components
    components:{
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    color: Colors.primary,
                }
            }
        },  MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                }
            }
        }
    }
       
    
});

export default theme;