//react
import { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { authContext } from "../../Context/authContext";
import { userContext } from "../../Context/userContext";

//style
import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Avatar,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../Styles/Styles";
import "./header.css";

const Header = () => {
  //context
  const ctx = useContext(authContext);
  const { user, statusState, status } = useContext(userContext);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  //icon
  function LookingIcon(props) {
    return <img className="Logo" src="./assets/icon/L4 Logo.svg" alt="Logo" />;
  }
  function LogoutIcon(props) {
    return (
      <img className="Logout" src="./assets/icon/Logout.svg" alt="Logout" />
    );
  }
  useEffect(() => {
    // Extract the current page from the pathname
    const currentPage = location.pathname.slice(1);

    // Treat both "/" and "/People" as the same active page
    setActivePage(currentPage === "People" ? "" : currentPage);
  }, [location.pathname]);

  //status color
  const [statusColor, setStatusColor] = useState("greenColor");
  // pages
  const pages = ["people", "events", "admin"];
  //status
  const settings = [
    {
      name: "Visible",
      status: "0",
      img: "./assets/icon/Dot.svg",
      className: "green",
    },
    {
      name: "Int. Only",
      status: "1",
      img: "./assets/icon/Dot.svg",
      className: "yellow",
    },
    {
      name: "Invisible",
      status: "2",
      img: "./assets/icon/Dot.svg",
      className: "red",
    },
  ];

  // *******************************************************************************************************
  // handle menu
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // *******************************************************************************************************
  // status
  useEffect(() => {
    if (ctx.user !== null) {
      console.log(ctx.user.user.status);
      handleStatus(ctx.user.user.status);
    }
  }, [user]);

  const handleStatus = (status) => {
    if (status !== ctx.user.user.status) {
      statusState(status);
    }

    if (status === "0") {
      setStatusColor("greenColor");
    } else if (status === "1") {
      setStatusColor("yellowColor");
    } else if (status === "2") {
      setStatusColor("redColor");
    }
  };

  const handleNavOpen = () => {};
  // *******************************************************************************************************
  // logout
  const logout = () => {
    // window.open("http://localhost:5000/auth/logout", "_self");
    ctx.logout();
  };

  return (
    <ThemeProvider theme={theme}>
      {user !== undefined && user !== null ? (
        <>
          <AppBar
            position="static"
            color="white"
            sx={{ position: "absolute", zIndex: "2" }}
            elevation={6}
          >
            <Container maxWidth="xl">
              <Toolbar sx={{ textAlign: "center" }}>
                {/* User menu */}
                <Box
                  sx={{
                    flexGrow: { xs: 2, md: 2, lg: 1 },
                    display: { xs: "flex" },
                  }}
                >
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={user?.user?.name}
                        src={`data:image/jpeg;base64,${user?.user?.profile_image}`}
                      />
                      <img
                        className={statusColor}
                        src="./assets/icon/Dot.svg"
                        alt="dot"
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting, index) => (
                      <MenuItem
                        key={index}
                        sx={{
                          display: "flex",
                          textAlign: "start",
                          justifyContent: "space-between",
                          bgcolor: "",
                        }}
                        onClick={handleCloseUserMenu}
                      >
                        {/* add button */}
                        <Box
                          onClick={() => handleStatus(setting.status)}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            textAlign: "center",
                            borderTopRightRadius: "25px",
                            borderBottomRightRadius: "25px",
                            borderBottom: "2px solid lightgrey",
                            borderRight: "2px solid lightgrey",
                            width: "7rem",
                          }}
                        >
                          <Typography
                            color="#886BBC"
                            sx={{ fontFamily: "SegoeUi", paddingTop: "5px" }}
                            textAlign="center"
                          >
                            {setting.name}{" "}
                          </Typography>
                          <Box sx={{ paddingTop: "5px", paddingRight: "5px" }}>
                            <img
                              className={setting.className}
                              src={setting?.img}
                              alt={setting.img}
                            />
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                    <Divider color="#886BBC" sx={{ height: "1px" }} />
                    <MenuItem
                      sx={{ display: "flex", flexDirection: "column" }}
                      onClick={handleCloseUserMenu}
                    >
                      <IconButton onClick={logout}>
                        <LogoutIcon />
                      </IconButton>
                    </MenuItem>
                  </Menu>
                </Box>
                {/* Logo when small interface */}
                <Box
                  sx={{
                    flexGrow: { xs: 1 },
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <Link to={"/people"}>
                    <IconButton>
                      <LookingIcon color="primary" />
                    </IconButton>
                  </Link>
                </Box>

                {/* Menu hamburger */}
                <Box
                  sx={{
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    display: { xs: "flex", md: "none" },
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon color="primary" />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {/* Link to Page */}
                    {pages.map((page) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Link to={`/${page}`}>
                          <Typography
                            sx={{
                              fontFamily: "SegoeUi",
                              textTransform: "capitalize",
                            }}
                            variant="subtitle2"
                          >
                            {page}
                          </Typography>
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>

                {/* Logo when large interface */}
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    ml: { xs: "0", lg: "175px" },
                    justifyContent: "center",
                  }}
                >
                  <Link to={"/people"}>
                    <IconButton>
                      <LookingIcon color="primary" />
                    </IconButton>
                  </Link>
                </Box>

                {/* Nav when large interface */}
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "flex-end",
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, display: "block" }}
                    >
                      <Link className="link" to={`/${page}`}>
                        <Typography
                          sx={{
                            fontFamily: "SegoeUi",
                            textTransform: "capitalize",
                            color: page === activePage || (page === "people" && activePage === "") ? "#886BBC" : "#C3B4DD", // Change text color based on active page
                          }}
                          variant="subtitle2"
                        >
                          {page}
                        </Typography>
                      </Link>
                    </MenuItem>
                  ))}
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        </>
      ) : (
        <div>loading</div>
      )}
    </ThemeProvider>
  );
};
export default Header;
