//react
import React from "react";
import { useState, useEffect, useContext, useRef, useReducer } from "react";
import GoogleMapReact from "google-map-react";
import { userContext } from "../../Context/userContext.js";
import { locationContext } from "../../Context/locationContext.js";
//style
import theme from "../../Styles/Styles";
import { ThemeProvider } from "@mui/material/styles";
import { Container, Box, Typography, Button, IconButton, Tooltip, Pagination, Stack } from "@mui/material";
import "../Map/GoogleMap.css";
import "./Admin.css";
//components
import FormAdd from "../../components/FormAdd/FormAdd";
import ImportWindow from "../../components/DialogWindow/ImportWindow";
import Loading from "../../components/Loading/Loading";
import Alerts from "../../components/Alerts/Alerts.js";
import DeleteWindowAll from "../../components/DialogWindow/DeleteWindowAll.js";
import Instruction from "../../components/DialogWindow/Instruction.js";
//moment
import moment from "moment";
//npm csv
import { CSVLink } from "react-csv";
import CsvDownloader from "react-csv-downloader";
import Papa from "papaparse";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";

const Admin = () => {
  // Refresh blocker
  window.addEventListener("beforeunload", (event) => {
    event.returnValue = `Are you sure you want to leave?`;
  });
  // Default props for the map
  const defaultProps = {
    center: {
      lat: 41.890214172631346,
      lng: 12.492230897824744,
    },
    zoom: 15,
  };
  const [mapZoom, setMapZoom] = useState(defaultProps.zoom);
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const { user } = useContext(userContext);
  const { currentLocation } = useContext(locationContext);
  const [isLoading, setIsLoading] = useState(true);
  const [mesEvents, setMesEvents] = useState([]); // have all events registered
  const [eventsMatched, setEventsMatched] = useState([]); // Matched first pull request events registered
  const [alert, setAlert] = useState({
    severity: "",
    message: "",
    isOpen: false,
  });
  //Converte base64 to Blob
  function base64ToBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }
  // Handle Delete API
  const eventsDelete = async (eventId) => {
    try {
      const res = await fetch(
        "https://looking4-api.mobileappslabs.ca/events/delete",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: user.token,
            userid: user.user.id,
          },
          body: new URLSearchParams({
            id: parseInt(eventId),
          }),
        }
      );
      // if successful res between 200 and 299
      if (res.ok) {
        console.log("delete successfully");
        return setAlert({
          severity: "success",
          message: "The event as been delete successfully",
          isOpen: true,
        });
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  // Reduser
  const ACTIONS = {
    FETCH_SUCCESS: "FETCH_SUCCESS",
    EVENT_UPDATED: "EVENT_UPDATED",
    UPDATE_EVENT_VISIBILITY: "UPDATE_EVENT_VISIBILITY",
    PHOTO_UPDATED: "PHOTO_UPDATED",
    START_DATE_UPDATED: "START_DATE_UPDATED",
    END_DATE_UPDATED: "END_DATE_UPDATED",
    ADD_EVENT: "ADD_EVENT",
    DUPLICATE_EVENT: "DUPLICATE_EVENT",
    DELETE_EVENT: "DELETE_EVENT",
    DELETE_ALL_EVENTS: "DELETE_ALL_EVENTS",
    GET_EVENT: "GET_EVENT",
    PUBLISH_EVENT: "PUBLISH_EVENT",
    SET_MEDIA: "SET_MEDIA",
    ADD_PHOTO: "ADD_PHOTO",
    PAGE_CHANGED: "PAGE_CHANGED",
    PAGE_CHANGED_NOFETCH: "PAGE_CHANGED_NOFETCH",
  };
  const initialState = {
    mesEvents: [], // Your initial state for mesEvents
    eventsMatched: [],
    updateEventCallCount: [], // Number of
    pageCount: 1,
    currentPage: 1,
    visitedPages: [1],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.FETCH_SUCCESS://***FETCH_SUCCESS***
        return {
          ...state,
          mesEvents: state.mesEvents.concat(action.data),
          eventsMatched: state.eventsMatched.concat(action.data),
          updateEventCallCount: state.updateEventCallCount.concat(action.updateNumber),
          pageCount: action.pageCount,
        };
      case ACTIONS.EVENT_UPDATED:
        const { prop, valeur, index } = action.payload;
        return {
          ...state,
          mesEvents: state.mesEvents.map((event, i) => {
            return index !== i
              ? event
              : {
                  ...event,
                  [prop]: valeur,
                  publish: false,
                };
          }),
        };
      case ACTIONS.UPDATE_EVENT_VISIBILITY://***UPDATE_EVENT_VISIBILITY***
        const { value, indexV } = action.payload;  
        return {
          ...state,
          mesEvents: state.mesEvents.map((event, i) => {
            if (indexV !== i) {
              return event;
            } else {
              // Update the event properties
              const updatedEvent = {
                ...event,
                visibility: value,
              };

              if (event.publish && state.updateEventCallCount[indexV]?.update >= 1) {
                updatedEvent.publish = false;
              }

              return updatedEvent;
            }
          }),
          updateEventCallCount: state.updateEventCallCount.map((count, i) => {
            return indexV !== i
            ? count
            : {
                ...count,
                update: + 1,
              };
          }) , // Increment after updating
        };
      case ACTIONS.PHOTO_UPDATED: //***PHOTO_UPDATED***
        const { propPhoto, newFile, indexEvent, indexPhoto } = action.payload;
        return {
          ...state,
          mesEvents: state.mesEvents.map((event, i) => {
            if (indexEvent !== i) {
              return event;
            } else {
              const updatedPhotos = event.photos.map((photo, j) => {
                if (indexPhoto !== j) {
                  return photo;
                } else {
                  return {
                    ...photo,
                    [propPhoto]: newFile,
                    index: indexPhoto,
                    mediaType: "1",
                  };
                }
              });

              return {
                ...event,
                photos: updatedPhotos,
                publish: false,
              };
            }
          }),
        };
      case ACTIONS.START_DATE_UPDATED: //***START_DATE_UPDATED***
        return {
          ...state,
          mesEvents: state.mesEvents.map((event, i) => {
            return {
              ...event,
              start: action.payload.valeur._d,
              publish: false,
            };
          }),
        };
      case ACTIONS.END_DATE_UPDATED: //***END_DATE_UPDATED***
        return {
          ...state,
          mesEvents: state.mesEvents.map((event, i) => {
            return {
              ...event,
              end: action.payload.valeur._d,
              publish: false,
            };
          }),
        };
      case ACTIONS.ADD_EVENT: //***ADD_EVENT***
        const eventLimitADD = user.user.is_premium ? 100 : 1;
        if (state.mesEvents.length <= eventLimitADD) {
          return {
            ...state,
            mesEvents: state.mesEvents.concat({
              eventName: "",
              eventType: "",
              description: "",
              locationName: "",
              locationLat: "",
              locationLng: "",
              startingDate: "",
              endingDate: "",
              visibility: "",
              eventId: "0",
              photos: [],
              publish: false,
            }),
          };
        } else {
          console.log("full events list");
          // Handle the case where the user has reached the limit of events
          // You can display a message or take other actions as needed
          return state;
        }
      case ACTIONS.DUPLICATE_EVENT: //***DUPLICATE_EVENT***
        const { eventToDuplicate } = action.payload;
        const eventLimitDuplicate = user.user.is_premium ? 100 : 1;
        if (state.mesEvents.length <= eventLimitDuplicate) {
          return {
            ...state,
            mesEvents: state.mesEvents.concat({
              eventName: eventToDuplicate.eventName,
              eventType: eventToDuplicate.eventType,
              description: eventToDuplicate.description,
              locationName: eventToDuplicate.locationName,
              locationLat: eventToDuplicate.locationLat,
              locationLng: eventToDuplicate.locationLng,
              startingDate: eventToDuplicate.startingDate,
              endingDate: eventToDuplicate.endingDate,
              visibility: eventToDuplicate.visibility,
              eventId: "0",
              photos: eventToDuplicate.photos,
              publish: false,
            }),
          };
        } else {
          console.log("full events list");
          // Handle the case where the user has reached the limit of events
          // You can display a message or take other actions as needed
          return state;
        }
      case ACTIONS.DELETE_EVENT: //***DELETE_EVENT***
        const { indexDelete, eventId } = action.payload;
        const eventLimitDelete = user.user.is_premium ? 100 : 1;
        if (state.mesEvents.length <= eventLimitDelete) {
          // Delete the event with the specified index
          const updatedEvents = state.mesEvents.filter((input, i) => {
            if (i === indexDelete) {
              if (eventId !== "0") {
                // You can call the eventsDelete function here if needed
                eventsDelete(eventId);
              }
              return false; // Remove the event
            } else {
              return true; // Keep other events
            }
          });

          return {
            ...state,
            mesEvents: updatedEvents,
          };
        } else {
          console.log("not working delete button click");
          // Handle the case where the user cannot delete more events
          // You can display a message or take other actions as needed
          return state;
        }
      case ACTIONS.DELETE_ALL_EVENTS: //***DELETE_ALL_EVENTS***
        const eventLimitDAll = user.user.is_premium ? 100 : 1;
        // Iterate through events and delete those with non-zero eventIds
        state.mesEvents.forEach((event) => {
          if (event.eventId !== "0") {
            // You can call the eventsDelete function here if needed
            eventsDelete(event.eventId);
          }
        });

        if (state.mesEvents.length <= eventLimitDAll) {
          return {
            ...state,
            mesEvents: [], // Empty the mesEvents array
          };
        } else {
          console.log("not working delete all button click");
          // Handle the case where the user cannot delete all events
          // You can display a message or take other actions as needed
          return state;
        }
      case ACTIONS.GET_EVENT: //***GET_EVENT***
        const { indexGet, blobDataArray } = action.payload;

        const updatedMesEventsMedia = state.mesEvents.map((event, i) => {
          return indexGet !== i
            ? event
            : {
                ...event,
                photos: blobDataArray,
              };
        });

        const updatedEventsMatchedMedia = state.eventsMatched.map(
          (event, i) => {
            return indexGet !== i
              ? event
              : {
                  ...event,
                  photos: blobDataArray,
                };
          }
        );

        // Set updated state
        return {
          ...state,
          mesEvents: updatedMesEventsMedia,
          eventsMatched: updatedEventsMatchedMedia,
        };

      case ACTIONS.PUBLISH_EVENT: //***PUBLISH_EVENT***
        const { result, publishEvent } = action.payload;
        const updatedMesEvents = state.mesEvents.map((event) => {
          return publishEvent !== event
            ? event
            : {
                ...event,
                eventId: result.id,
                publish: true,
              };
        });
        // Set updated state
        return {
          ...state,
          mesEvents: updatedMesEvents,
        };
      case ACTIONS.SET_MEDIA: //***SET_MEDIA***
        const { indexMedia } = action.payload;

        const updatedMedia = state.mesEvents.map((event, i) => {
          return indexMedia !== i
            ? event
            : {
                ...event,
                publish: false,
              };
        });

        return {
          ...state,
          mesEvents: updatedMedia,
          alert: {
            severity: "error",
            message: "The media has not been saved correctly, please try again",
            isOpen: true,
          },
        };

      case ACTIONS.ADD_PHOTO: //***ADD_PHOTO***
        const { indexEventAddPhoto } = action.payload;

        if (state.mesEvents[indexEventAddPhoto].photos.length <= 5) {
          const newPhoto = { base64: "", index: "", type: "" };

          const updatedEvent = {
            ...state.mesEvents[indexEventAddPhoto],
            photos: [...state.mesEvents[indexEventAddPhoto].photos, newPhoto],
          };

          const updatedMesEvents = [...state.mesEvents];
          updatedMesEvents[indexEventAddPhoto] = updatedEvent;

          return {
            ...state,
            mesEvents: updatedMesEvents,
          };
        } else {
          console.log("full events list");
          return state;
        }
      case ACTIONS.PAGE_CHANGED: //***PAGE_CHANGED***
        return {
            ...state,
            mesEvents: state.mesEvents.concat(action.dataIndex),
            eventsMatched: state.eventsMatched.concat(action.dataIndex),
            updateEventCallCount: state.updateEventCallCount.concat(action.updateNumberIndex),
            currentPage: action.pageValue,
            visitedPages: state.visitedPages.concat(action.pageValue),
          }
      case ACTIONS.PAGE_CHANGED_NOFETCH:
        const { pageValue } = action.payload;
        return {
          currentPage: pageValue,
        }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);





  const publish = async (publishEvent) => {
    console.log("Publish event");
    try {
      const res = await fetch(
        "https://looking4-api.mobileappslabs.ca/events/publish",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: user.token,
            userid: user.user.id,
          },
          body: new URLSearchParams({
            id: parseInt(publishEvent.eventId), //create id = 0 || -1 else if edit event id
            name: encodeURIComponent(publishEvent.eventName),
            tag: publishEvent.eventType,
            desc: encodeURIComponent(publishEvent.description),
            ln: encodeURIComponent(publishEvent.locationName),
            llat: parseFloat(publishEvent.locationLat),
            llng: parseFloat(publishEvent.locationLng),
            start: moment(publishEvent.startingDate).valueOf() / 1000, // miliseconds et transforme en secondes
            end: moment(publishEvent.endingDate).valueOf() / 1000, // miliseconds et transforme en secondes
            priv: parseInt(publishEvent.visibility),
          }),
        }
      );
      // return id
      console.log("Publish event trying to publish");
      const result = await res.json();
      console.log("result: " + result);
      if (res.ok) {
        // Define the action creator for publishing an event
        console.log(result);
        dispatch({
          type: ACTIONS.PUBLISH_EVENT,
          payload: { result, publishEvent },
        });

        // verifier si c<est les meme photo ou non

        if (publishEvent.photos.length > 0) {
          console.log("Set MediaEvents");

          // Create an array of promises for handling the FileReader operations
          const handleBase64Promises = publishEvent.photos.map((photo) => {
            return new Promise((resolve) => {
              handleBase64(photo.base64, function (base64String) {
                // Here, you can use the base64String as needed
                console.log("Base64 string:", base64String);
                resolve({
                  base64: base64String,
                  index: photo.index,
                  mediaType: photo.mediaType,
                });
              });
            });
          });

          // Wait for all FileReader operations to complete
          Promise.all(handleBase64Promises)
            .then((sendData) => {
              // All FileReader operations have completed, and sendData contains the data
              sendData.forEach((media, index) => {
                setMedia(result.id, media, index, publishEvent);
              });
            })
            .catch((error) => {
              // Handle any errors that might occur during FileReader operations
              console.error("Error reading files:", error);
            });
        }
        setAlert({
          severity: "success",
          message: "The event as been save successfully",
          isOpen: true,
        });
      } else {
        setAlert({
          severity: "error",
          message: "The event as not been save correctly, please try again",
          isOpen: true,
        });
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const setMedia = async (eventIdMedia, media, indexMedia) => {
    // dois setMedia un a la fois donc si 6 photo le faire 6 fois pour chaque
    try {
      const res = await fetch(
        "https://looking4-api.mobileappslabs.ca/events/setMedia",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: user.token,
            userid: user.user.id,
          },
          body: new URLSearchParams({
            eventId: parseInt(eventIdMedia),
            base64: media.base64,
            index: parseInt(media.index),
            type: parseInt(media.mediaType),
          }),
        }
      );

      const result = await res.json();
      console.log(res.ok);
      if (result.ok.status) {
        setAlert({
          severity: "success",
          message: "The event as been save successfully",
          isOpen: true,
        });
      } else {
        setAlert({
          severity: "error",
          message: "The media as not been save correctly, please try again",
          isOpen: true,
        });

        dispatch({
          type: ACTIONS.SET_MEDIA,
          payload: { indexMedia },
        });
      }
    } catch (err) {
      console.log(err, "didn't save media");
    }
  };
  const getEvent = async (eventIdGet, indexGet) => {
    if (!mesEvents[indexGet].photos.length > 0) {
      try {
        const event = await fetch(
          "https://looking4-api.mobileappslabs.ca/events/get",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: user.token,
              userid: user.user.id,
            },
            body: new URLSearchParams({
              id: eventIdGet,
              checksum: [],
            }),
          }
        );
        const data = await event.json();
        // console.log(data.medias.insert);
        // Create an array of promises for handling the FileReader operations
        const handleBlobPromises = data.medias.insert.map((media) => {
          return new Promise((resolve) => {
            const blob = base64ToBlob(media.base64, media.type); // Convert base64 to Blob directly
            resolve({
              base64: blob, // Blob object
              index: media.media_index, // The position of the image in the list of images
              mediaType: media.type,
            });
          });
        });

        // Wait for all FileReader operations to complete
        Promise.all(handleBlobPromises)
          .then((blobDataArray) => {
            // All FileReader operations have completed, and sendData contains the data

            dispatch({
              type: ACTIONS.GET_EVENT,
              payload: { indexGet, blobDataArray },
            });
          })

          .catch((error) => {
            // Handle any errors that might occur during FileReader operations
            console.error("Error reading files:", error);
          });
      } catch (err) {
        console.log(err.message, "no media");
        console.log(err.stack, "no media");
      }
    } else {
      console.log("already inserted");
    }
  };

  //  fetch all My Events from API
  const getMyEvents = async () => {
    setIsLoading(true); // Set isLoading to true before fetching
    console.log(state.currentPage)
    try {
      const listeEvent = await fetch(
        // "https://looking4-api.mobileappslabs.ca/events/getMyEvents",
        "https://looking4-api.mobileappslabs.ca/events/getMyEventsPageIndex",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: user.token,
            userid: user.user.id,
          },
          body: new URLSearchParams({
            ecs: [],
            ucs: [],
            ownedOnly: true,
            pageIndex: (state.currentPage - 1).toString(),
            pageSize: "10",
          }),
        }
      );
      const data = await listeEvent.json();
        console.log(toString(state.currentPage - 1));
      let equation = data.itemsCount / 10;
      let pageCount;
      if (!Number.isInteger(equation))
      {
        pageCount = Math.ceil(equation);
      } else {
        pageCount = equation;
      }

      let fetchData = [];
      let countData = [];
      for (let i = 0; i < data.events.insert.length; i++) {
        const event = data.events.insert[i];
        fetchData.push({
          eventName: decodeURIComponent(event.name), // name: nom de l'evenement
          eventType: event.tag, // tag: type/tag/interest
          description: decodeURIComponent(event.description), // desc: description
          locationName: decodeURIComponent(event.location_name), // ln: location name
          locationLat: event.location_latitude, // llat: location latitude
          locationLng: event.location_longitude, // llng: location longitude
          startingDate: moment.unix(event.start).format(), // start: date de début de l'evenement
          endingDate: moment.unix(event.end).format(), // end: date de fin
          visibility: event.privacy, // priv: (0 = invisible, 1 = private, 2 = followers only, 3 = on invitiation, 4 = open to all)
          eventId: event.id, //l'id de l'évènement
          publish: true, // if publish true already in db
          photos: [],
        });
        countData.push({update: 0});
      }
      dispatch({ type: "FETCH_SUCCESS", data: fetchData, updateNumber: countData, pageCount: pageCount  });
      setIsLoading(false); // Set isLoading to false after fetching

    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  //  fetch all My Events from API
  const getMyEventsPageIndex = async (event, pageValue) => {
    setIsLoading(true); // Set isLoading to true before fetching
    console.log("getMyEventsPageIndex", pageValue);
    try {
      const listeEvent = await fetch(
        // "https://looking4-api.mobileappslabs.ca/events/getMyEvents",
        "https://looking4-api.mobileappslabs.ca/events/getMyEventsPageIndex",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: user.token,
            userid: user.user.id,
          },
          body: new URLSearchParams({
            ecs: [],
            ucs: [],
            ownedOnly: true,
            pageIndex: (pageValue - 1).toString(),
            pageSize: "10",
          }),
        }
      );
      const data = await listeEvent.json();

      let fetchData = [];
      let countData = [];
      for (let i = 0; i < data.events.insert.length; i++) {
        const event = data.events.insert[i];
        fetchData.push({
          eventName: decodeURIComponent(event.name), // name: nom de l'evenement
          eventType: event.tag, // tag: type/tag/interest
          description: decodeURIComponent(event.description), // desc: description
          locationName: decodeURIComponent(event.location_name), // ln: location name
          locationLat: event.location_latitude, // llat: location latitude
          locationLng: event.location_longitude, // llng: location longitude
          startingDate: moment.unix(event.start).format(), // start: date de début de l'evenement
          endingDate: moment.unix(event.end).format(), // end: date de fin
          visibility: event.privacy, // priv: (0 = invisible, 1 = private, 2 = followers only, 3 = on invitiation, 4 = open to all)
          eventId: event.id, //l'id de l'évènement
          publish: true, // if publish true already in db
          photos: [],
        });
        countData.push({update: 0});
      }
      dispatch({ type: "PAGE_CHANGED", dataIndex: fetchData, updateNumberIndex: countData, pageValue: pageValue });
      setIsLoading(false); // Set isLoading to false after fetching


    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };
    //********************************************************************************************************************************************** */
  //********************************************************************************************************************************************** */
  // function everytime user changes
  useEffect(() => {
    if (user !== null) {
      // getMyEvents();
      setTimeout(() => {
        getMyEvents();
      }, 2000);
    }
  }, []);

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // Handle all update for mesEvents
  // Dispatch the EVENT_UPDATED action when you want to update an event
  const updateEvent = (prop, valeur, index) => {
    dispatch({ type: ACTIONS.EVENT_UPDATED, payload: { prop, valeur, index } });
  };
  // Define the action creator for updating event description

  const updateEventDescription = (index, value) => {
    const newDescription = value;
    // const index = /* Your index logic here */;
    dispatch({
      type: ACTIONS.EVENT_UPDATED,
      payload: {
        prop: 'description',
        valeur: newDescription,
        index,
      },
    });
  };
  // Define the action creator for updating event visibility
  const updateEventVisibility = (value, indexV) => {
    dispatch({
      type: ACTIONS.UPDATE_EVENT_VISIBILITY,
      payload: { value, indexV },
    });
  };

  // Define the action creator for updating a photo
  const updatePhoto = (propPhoto, newFile, indexEvent, indexPhoto) => {
    dispatch({
      type: ACTIONS.PHOTO_UPDATED,
      payload: { propPhoto, newFile, indexEvent, indexPhoto },
    });
  };

  // Define the action creator for updating the start date
  const updateStartDate = (valeur) => {
    dispatch({
      type: ACTIONS.START_DATE_UPDATED,
      payload: { valeur },
    });
  };

  // Define the action creator for updating the end date
  const updateEndDate = (valeur) => {
    dispatch({
      type: ACTIONS.END_DATE_UPDATED,
      payload: { valeur },
    });
  };

  // Define the action creator for adding an event
  const onAddBtnClick = () => {
    dispatch({ type: ACTIONS.ADD_EVENT });
  };

  // Define the action creator for duplicating an event
  const onDuplicateBtnClick = (eventToDuplicate) => {
    dispatch({
      type: ACTIONS.DUPLICATE_EVENT,
      payload: { eventToDuplicate },
    });
  };

  // Handle single Delete event
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const handleOpenDelete = () => {
    setIsOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setIsOpenDelete(false);
  };

  // Define the action creator for deleting an event
  const onDeletedBtnClick = (indexDelete, eventId) => {
    dispatch({
      type: ACTIONS.DELETE_EVENT,
      payload: { indexDelete, eventId },
    });
  };

  // Handle Delete All
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [isOpenDeleteAll, setIsOpenDeleteAll] = useState(false);

  const handleOpenDeleteAll = () => {
    setIsOpenDeleteAll(true);
  };
  const handleCloseDeleteAll = () => {
    setIsOpenDeleteAll(false);
    setDeleteConfirmation("");
    setIsConfirmed(true);
    console.log("set False");
  };

  const updateConfirmation = (props) => {
    setDeleteConfirmation(props);
    if (props === "delete") {
      setIsConfirmed(false);
    }
  };

  // Define the action creator for deleting all events
  const onDeleteAll = () => {
    dispatch({
      type: ACTIONS.DELETE_ALL_EVENTS,
    });
  };

  // Define the action creator for adding a photo
  const onAddPhoto = (indexEventAddPhoto) => {
    dispatch({
      type: ACTIONS.ADD_PHOTO,
      payload: { indexEventAddPhoto },
    });
  };

    // Define the action creator for adding a photo
    const onPageChanged = (event, pageValue) => {
      console.log("onPageChanged", pageValue);
      dispatch({
        type: ACTIONS.PAGE_CHANGED_NOFETCH,
        payload: { pageValue },
      });
    };

  // Handle Map
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    mapRef.current = map;
    setMapReady(true);
  };

  const mapOnChange = (map) => {
    if (map.zoom !== mapZoom) {
      // console.log("map zoom changed");
    }
  };

  const handleBase64 = (prop, callback) => {
    var file = prop,
      reader = new FileReader();

    // console.log("you have changed");

    reader.onloadend = function () {
      // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
      var b64 = reader.result.replace(/^data:.+;base64,/, "");
      console.log(b64); // -> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="

      // Call the callback function with the Base64 string
      callback(b64);
    };

    reader.readAsDataURL(file);
  };

  // Function to compare two objects except for "publish" and "photos"
  function areObjectsEqual(obj1, obj2) {
    const keysToIgnore = ["publish", "photos"];

    for (const key in obj1) {
      if (!keysToIgnore.includes(key) && obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  // Look at all events and only publish the one that have been changed
  const filterPublish = async () => {
    console.log("Filtering");
    let publishEvents = state.mesEvents.filter((event) => !event.publish);
    publishEvents.forEach((event, i) => {
      if (event.eventId !== "0" && areObjectsEqual(event, eventsMatched[i])) {
        let sendData = [];
        for (let i = 0; i < event.photos.length; i++) {
          const photo = event.photos[i];
          sendData.push({
            base64: handleBase64(photo.base64), //l'image en base 64
            index: photo.index, //la position de l'image dans la liste des images new Blob([event.media_index], { type: 'image/png' });
            mediaType: photo.type,
          });
        }

        event.photos.forEach((media, index) => {
          setMedia(event.eventId, media, index);
        });
      } else {
        console.log(
          "Some properties have changed other than 'publish' and 'photos'."
        );
        publish(event);
      }
    });
  };

  //********************************************************************************************************************************************** */
  //********************************************************************************************************************************************** */
  // csv download template
  const headersTemplate = [
    { label: "Name", key: "eventName" },
    { label: "Type", key: "evenType" },
    { label: "Description", key: "description" },
    { label: "Start_date", key: "startingDate" },
    { label: "End_date", key: "endingDate" },
    { label: "Lng", key: "locationLng" },
    { label: "Lat", key: "locationLat" },
    { label: "Location_name", key: "locationName" },
    { label: "Photo_1", key: "photo1" },
    { label: "Photo_2", key: "photo2" },
    { label: "Photo_3", key: "photo3" },
    { label: "Photo_4", key: "photo4" },
    { label: "Photo_5", key: "photo5" },
    { label: "Photo_6", key: "photo6" },
    { label: "Privacy", key: "visibility" },
  ];

  const dataTemplate = [
    {
      eventName: "Example: Event name",
      evenType: "Party",
      description: "description",
      startingDate: "yyyy/mm/dd hh:ss",
      endingDate: "yyyy/mm/dd hh:ss",
      locationLng: "-45.45",
      locationLat: "71.71",
      locationName: "Event location name",
      photo1: "Image in base64",
      photo2:
        "You can use tool like this 'https://onlinejpgtools.com/convert-jpg-to-base64' to help you ",
      photo3:
        "iVBORw0KGgoAAAANSUhEUgAACRcAAATWCAYAAAC1nAMnAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAP+lSURBVHhe7P0HlGRHlh0IFsnd5ezsnDPc4cxyh00OmzvcnSH37A53hstDLltUVVer6ulm6xKoapYECloUREGrhEitkAKJ1FrLSBmRGSlDa60j3MPdQ0fKiEiFenvvu9/ie0QGgESiUCwMPU/ecHf7Zs+ePXv27Jn99",
      photo4:
        "iVBORw0KGgoAAAANSUhEUgAACRcAAATWCAYAAAC1nAMnAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAP+lSURBVHhe7P0HlGRHlh0IFsnd5ezsnDPc4cxyh00OmzvcnSH37A53hstDLltUVVer6ulm6xKoapYECloUREGrhEitkAKJ1FrLSBmRGSlDa60j3MPdQ0fKiEiFenvvu9/ie0QGgESiUCwMPU/ecHf7Zs+ePXv27Jn99",
      photo5:
        "iVBORw0KGgoAAAANSUhEUgAACRcAAATWCAYAAAC1nAMnAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAP+lSURBVHhe7P0HlGRHlh0IFsnd5ezsnDPc4cxyh00OmzvcnSH37A53hstDLltUVVer6ulm6xKoapYECloUREGrhEitkAKJ1FrLSBmRGSlDa60j3MPdQ0fKiEiFenvvu9/ie0QGgESiUCwMPU/ecHf7Zs+ePXv27Jn99",
      photo6:
        "iVBORw0KGgoAAAANSUhEUgAACRcAAATWCAYAAAC1nAMnAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAP+lSURBVHhe7P0HlGRHlh0IFsnd5ezsnDPc4cxyh00OmzvcnSH37A53hstDLltUVVer6ulm6xKoapYECloUREGrhEitkAKJ1FrLSBmRGSlDa60j3MPdQ0fKiEiFenvvu9/ie0QGgESiUCwMPU/ecHf7Zs+ePXv27Jn99",
      visibility:
        "0 = invisible, 1 = private, 2 = followers only, 3 = on invitiation, 4 = open to all, 5 = By type only",
    },
  ];
  const csvTemplate = {
    data: dataTemplate,
    headers: headersTemplate,
    filename: "L4-Event-Template.csv",
  };
  //********************************************************************************************************************************************** */
  //********************************************************************************************************************************************** */
  // csv export
  const headers = [
    { displayName: "Name", id: "eventName" },
    { displayName: "Type", id: "eventType" },
    { displayName: "Description", id: "description" },
    { displayName: "Start_date", id: "startingDate" },
    { displayName: "End_date", id: "endingDate" },
    { displayName: "Lng", id: "locationLng" },
    { displayName: "Lat", id: "locationLat" },
    { displayName: "Location_name", id: "locationName" },
    { displayName: "Photo_1", id: "photo1" },
    { displayName: "Photo_2", id: "photo2" },
    { displayName: "Photo_3", id: "photo3" },
    { displayName: "Photo_4", id: "photo4" },
    { displayName: "Photo_5", id: "photo5" },
    { displayName: "Photo_6", id: "photo6" },
    { displayName: "Privacy", id: "visibility" },
  ];

  const reportCsv = async () => {
    let csvReportData = [];
    try {
      for (let i = 0; i < state.mesEvents.length; i++) {
        const event = state.mesEvents[i];
        const handleBase64Promises = event.photos.map((photo) => {
          return new Promise((resolve, reject) => {
            handleBase64(photo.base64, function (base64String) {
              if (base64String) {
                resolve({
                  base64: base64String,
                  index: photo.index,
                  mediaType: photo.mediaType,
                });
              } else {
                reject(new Error("Failed to convert to base64."));
              }
            });
          });
        });

        // Wait for all FileReader operations to complete
        const photosData = await Promise.all(handleBase64Promises);

        csvReportData.push({
          eventName: event.eventName,
          eventType: event.eventType,
          description: event.description,
          locationName: event.locationName,
          locationLat: event.locationLat,
          locationLng: event.locationLng,
          startingDate: event.startingDate,
          endingDate: event.endingDate,
          visibility: event.visibility,
          eventId: event.eventId,
          photo1: photosData[0]?.base64 || "",
          photo2: photosData[1]?.base64 || "",
          photo3: photosData[2]?.base64 || "",
          photo4: photosData[3]?.base64 || "",
          photo5: photosData[4]?.base64 || "",
          photo6: photosData[5]?.base64 || "",
        });
      }
      return Promise.resolve(csvReportData);
    } catch (error) {
      console.error("Error reading files:", error);
      // Depending on your requirements, you may want to handle the error here.
      // For now, the function will return an empty array if any error occurs.
      return [];
    }
  };

  console.log("mesEvents", state.mesEvents, "eventsMatched", state.eventsMatched, "pageCount", state.pageCount, "currentPage", state.currentPage);
  //********************************************************************************************************************************************** */
  //********************************************************************************************************************************************** */
  // csv import

  const [isOpenImport, setIsOpenImport] = useState(false);

  const handleOpenImport = () => {
    setIsOpenImport(true);
  };
  const handleCloseImport = () => {
    setIsOpenImport(false);
  };
  const [file, setFile] = useState(null);
  const onInputChange = (newFile) => {
    setFile(newFile);
  };
  // console.log(file);

  const handleImportCsv = (file) => {
    // Check if a file has been selected
    if (!file) {
      alert("Please select a CSV file");
      return;
    }

    // Read the selected file
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;

      // Parse CSV data using Papa.parse
      Papa.parse(csvData, {
        header: true, // Treat the first row as headers
        complete: (result) => {
          // Convert the parsed data into an array of objects
          const dataArray = result.data;
          // Do something with the dataArray, for example, set it in state
          // setData(dataArray);
          console.log(dataArray);
          let data = [];
          for (let i = 0; i < dataArray.length; i++) {
            const event = dataArray[i];
            data.push({
              eventName: event.Name,
              eventType: event.Type,
              description: event.Description,
              locationName: event.Location_name,
              locationLat: event.Lat,
              locationLng: event.Lng,
              startingDate: event.Starting_date,
              endingDate: event.Ending_date,
              visibility: event.privacy,
              eventId: "0",
              photos: [],
              pusblish: false,
            });

            let photoData = [];
            if (event.Photo_1 !== "") {
              photoData.push({
                base64: event.Photo_1,
                index: "1",
                mediaType: "1",
              });

              if (event.Photo_2 !== "") {
                photoData.push({
                  base64: event.Photo_2,
                  index: "1",
                  mediaType: "1",
                });

                if (event.Photo_3 !== "") {
                  photoData.push({
                    base64: event.Photo_3,
                    index: "1",
                    mediaType: "1",
                  });

                  if (event.Photo_4 !== "") {
                    photoData.push({
                      base64: event.Photo_4,
                      index: "1",
                      mediaType: "1",
                    });

                    if (event.Photo_5 !== "") {
                      photoData.push({
                        base64: event.Photo_5,
                        index: "1",
                        mediaType: "1",
                      });

                      if (event.Photo_6 !== "") {
                        photoData.push({
                          base64: event.Photo_6,
                          index: "1",
                          mediaType: "1",
                        });
                      }
                    }
                  }
                }
              }
            }

            const handleBlobPromises = photoData.map((media, i) => {
              return new Promise((resolve) => {
                const blob = base64ToBlob(media.base64, media.type); // Convert base64 to Blob directly
                resolve({
                  base64: blob, // Blob object
                  index: media.index, // The position of the image in the list of images
                  mediaType: media.mediaType,
                });
              });
            });

            // Wait for all FileReader operations to complete
            Promise.all(handleBlobPromises)
              .then((photos) => {
                // All FileReader operations have completed, and sendData contains the data
                photos.forEach((photo) => {
                  data.photos.push(photo);
                });
                setMesEvents(state.mesEvents.concat(data));
              })
              .catch((error) => {
                // Handle any errors that might occur during FileReader operations
                console.error("Error reading files:", error);
              });
          }
        },
        error: (error) => {
          console.error("CSV parsing error:", error);
        },
      });
    };

    reader.readAsText(file);
  };
  const [isOpenInstruction, setIsOpenInstruction] = useState(false);

  const handleOpenInstruction = () => {
    setIsOpenInstruction(true);
  };
  const handleCloseInstruction = () => {
    setIsOpenInstruction(false);
  };

  //********************************************************************************************************************************************** */
  //********************************************************************************************************************************************** */
  // onClick show map
  const [mapActive, setMapActive] = useState("flex");
  const [myIndex, setMyIndex] = useState();

  const onClickShowMap = (i) => {
    setMapActive("none");
    setMyIndex(i);
  };

  const onClickMap = (i) => {
    setMapActive("flex");
    // updateEvent()
  };

  const onCloseAlert = () => {
    return setAlert({ isopen: false });
  };
  return (
    <Box style={{ height: "100%", width: "100%" }}>
      <Alerts alert={alert} onCloseAlert={onCloseAlert} />
      <Container
        maxWidth="xxl"
        sx={{
          backgroundColor: "white",
          position: "absolute",
          zIndex: "1",
          top: "10%",
          left: "5%",
          width: "90%",
          height: "85%",
          borderRadius: "50px",
          display: mapActive,
          flexDirection: "column",
        }}
      >
        {/* title + input csv */}
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: "flex",
              position: "relative",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              
            }}
          >
            <Box
              sx={{
                margin: "10px",
                position: "absolute",
                right: "90%",
                
              }}
            >
              <Tooltip title="Need Help?">
                <IconButton
                  onClick={handleOpenInstruction}
                  aria-label="help outline icon"
                >
                  <HelpOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Instruction
                handleClose={handleCloseInstruction}
                open={isOpenInstruction}
              />
            </Box>
            <Box>
              <Typography
                sx={{ fontFamily: "SegoeUi" }}
                className="title-event"
                color="primary"
                variant="h5"
              >
                MANAGE EVENTS
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                position: "absolute",
                flexDirection: "row",
                left: "70%",
              }}
            >
              <CSVLink {...csvTemplate}>
                <Button
                  sx={{
                    margin: "10px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  <Typography
                    sx={{
                      fontFamily: "SegoeUi",
                      fontSize: "12px",
                      textTransform: "capitalize",
                    }}
                    className="button-cvs"
                    color="white"
                    variant="button"
                  >
                    Download CSV template
                  </Typography>
                </Button>
              </CSVLink>
              <CsvDownloader
                columns={headers}
                datas={reportCsv}
                filename="L4-Event"
                wrapColumnChar='"'
                extension=".csv"
              >
                <Button
                  sx={{
                    margin: "10px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  <Typography
                    sx={{
                      fontFamily: "SegoeUi",
                      fontSize: "12px",
                      textTransform: "capitalize",
                    }}
                    className="button-cvs"
                    color="white"
                    variant="button"
                  >
                    Export to CSV
                  </Typography>
                </Button>
              </CsvDownloader>
              <Box>
                <Button
                  sx={{
                    margin: "10px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={handleOpenImport}
                  disableElevation
                >
                  <Typography
                    sx={{
                      fontFamily: "SegoeUi",
                      fontSize: "12px",
                      textTransform: "capitalize",
                    }}
                    className="button-cvs"
                    color="white"
                    variant="button"
                  >
                    Import from CSV
                  </Typography>
                </Button>

                <ImportWindow
                  handleClose={handleCloseImport}
                  open={isOpenImport}
                  file={file}
                  onInputChange={onInputChange}
                  handleImportCsv={handleImportCsv}
                />
              </Box>
            </Box>
            {/* 
                    <InputLabel htmlFor="import-button" style={styles.importLabel}>
                        <Input
                            id="import-button"
                            inputProps={{
                            accept:
                                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                            }}
                            onChange={onInputChange}
                            style={styles.hidden}
                            type="file"
                        />
                        Import Spreadsheet
                    </InputLabel> */}
          </Box>
          {/* form */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: "2",
              paddingTop: "2rem",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {/* liste de form sadasd*/}
            {isLoading ? (
              <Loading />
            ) : state.mesEvents.length > 0 ? (
              state.mesEvents
              .slice((state.currentPage - 1) * 10, state.currentPage * 10) // Calculate the slice based on currentPage
              .map((event, i) => (
                <FormAdd
                  key={i}
                  index={(state.currentPage - 1) * 10 + i} // Adjust the index to match the actual array index
                  event={event}
                  updateEvent={updateEvent}
                  onAddBtnClick={onAddBtnClick}
                  updateStartDate={updateStartDate}
                  updateEndDate={updateEndDate}
                  updateEventVisibility={updateEventVisibility}
                  onDeletedBtnClick={onDeletedBtnClick}
                  onDuplicateBtnClick={onDuplicateBtnClick}
                  onClickShowMap={onClickShowMap}
                  getEvent={getEvent}
                  isOpenDelete={isOpenDelete}
                  handleCloseDelete={handleCloseDelete}
                  handleOpenDelete={handleOpenDelete}
                  onAddPhoto={onAddPhoto}
                  updatePhoto={updatePhoto}
                  updateEventDescription={updateEventDescription}
                  
                />
              ))
            ) : null}

            <Button
              sx={{
                margin: "1rem",
                height: "35px",
                width: "180px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              color="primary"
              variant="contained"
              onClick={onAddBtnClick}
              type="submit"
              disableElevation
            >
              <Typography
                sx={{
                  fontFamily: "SegoeUi",
                  flexGrow: "2",
                  fontSize: "15px",
                  textTransform: "capitalize",
                }}
                color="white"
                className="button-cvs"
                variant="button"
              >
                Create event
              </Typography>
              <img className="logo" src="assets/icon/Event Add.svg" alt="Add" />
            </Button>
          </Box>
          <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingBottom: "1rem",
          }}>
            <Stack spacing={2}>
              <Pagination color="primary" count={state.pageCount} page={state.currentPage} onChange={(e, pageValue) => state.visitedPages.includes(pageValue) ? onPageChanged(e, pageValue) : getMyEventsPageIndex(e, pageValue)} />
            </Stack>
          </Box>
          {/* button delete publish */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingBottom: "1rem",
            }}
          >
            <Button
              sx={{ marginRight: "10px", borderRadius: "10px" }}
              color="redDelete"
              type="submit"
              variant="contained"
              disableElevation
              onClick={handleOpenDeleteAll}
            >
              <Typography
                sx={{ fontFamily: "SegoeUi", textTransform: "capitalize" }}
                color="white"
                className="button-cvs"
              >
                Delete All
              </Typography>
            </Button>
            <DeleteWindowAll
              handleCloseDeleteAll={handleCloseDeleteAll}
              open={isOpenDeleteAll}
              btnDelete={onDeleteAll}
              updateConfirmation={updateConfirmation}
              deleteConfirmation={deleteConfirmation}
              isConfirmed={isConfirmed}
            />
            <Button
              sx={{ borderRadius: "10px" }}
              color="green"
              type="submit"
              onClick={filterPublish}
              variant="contained"
              disableElevation
            >
              <Typography
                sx={{ fontFamily: "SegoeUi", textTransform: "capitalize" }}
                color="white"
                className="button-cvs"
              >
                save all changes
              </Typography>
            </Button>
          </Box>
        </ThemeProvider>
      </Container>

      <Box
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          zIndex: "0",
        }}
      >
        {user !== undefined ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: [process.env.REACT_APP_GOOGLE_MAPS_API],
              libraries: ["places"],
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            // onChange={(map) => console.log('Map moved', map)}
            options={{
              fullscreenControl: false,
              zoomControl: false,
              mapTypeId: "hybrid",
              centerControl: true,
            }}
            center={currentLocation}
            onClick={(ev) => {
              updateEvent("locationLat", ev.lat, myIndex);
              updateEvent("locationLng", ev.lng, myIndex);
              setMapActive("flex");
            }}
          ></GoogleMapReact>
        ) : (
          <Loading />
        )}
      </Box>
    </Box>
  );
};
export default Admin;
