import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  CardMedia,

} from "@mui/material";
import { Link } from "react-router-dom";
const Instruction = ({ handleClose, open }) => {
  return (
    <>
      {open === undefined ? null : (
        <Dialog
          open={open}
          keepMounted
          disableEscapeKeyDown
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{ textAlign: "center" }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {/* backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})` */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "25px",
              width: "600px",
              height: "500px",
            }}
          >
            <DialogTitle sx={{ margin: "2rem" }}>
              <Typography
                sx={{
                  fontFamily: "SegoeUi",
                  margin: "1rem",
                  fontStyle: "italic",
                  fontSize: "1.5rem",
                }}
                textAlign="center"
                color="primary"
              >
                How to Use the Admin Page
              </Typography>
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", margin: "1rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
           
            
                }}
              >
               
                  <CardMedia
                    component="iframe"
                    src="https://www.youtube.com/embed/XJhwpowM8tQ"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    allowFullScreen
                    title="Embedded youtube"
                    sx={{
                      aspectRatio: "16/9",
                      width: "80%",
                      left:"10%",
                      position: "relative",
                      borderRadius: "25px",
                    }}
                  />
              

                
                  <Typography sx={{fontFamily: "SegoeUi",marginTop:"3rem"}}>
                    For support contact us at: <Link to={"/support"}>support@mobileappslabs.com</Link>
                  </Typography>
              
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default Instruction;
