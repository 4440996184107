import { Collapse, Alert, AlertTitle } from "@mui/material";
import { useState, useEffect, useContext, useRef, useReducer } from "react";
const Alerts = ({ alert, onCloseAlert }) => {
  
  useEffect(() => {

      setTimeout(() => {
        onCloseAlert();
      }, 2000);
    
  }, [alert.isOpen]);
  return (
    <Collapse in={alert.isOpen}>
      <Alert
        sx={{ position: "absolute", zIndex: "2", margin: "1em" }}
        severity={alert.severity || "info"}
        onClose={() => {
          onCloseAlert()
        }}
      >
        <AlertTitle sx={{ textTransform: "capitalize" }}>{alert.severity}</AlertTitle>
        {alert.message}
      </Alert>
    </Collapse>
  );
};

export default Alerts;
