//react
import { useState, useContext } from 'react';
import { authContext } from '../../Context/authContext';
import {useNavigate} from 'react-router-dom';
//Style
import { Container, Button, Box, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Divider } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import './Register.css';
import theme from '../../Styles/Styles';
// dialog
import DialogWindow from '../../components/DialogWindow/DialogWindow';
import DialogWindowSignUp from '../../components/DialogWindow/DialogWindowSignUp';

 const Register = () => {
    // const [Register, setRegister] = useState([{email: '',password:''}]);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const {login, user, errMessage, handleClose, handleDownload} = useContext(authContext);

    // const user =useContext(userContext);

    const updateEmail = (valeur, prop) => {

        setEmail(current => {
            return {
                ...current,
                [prop]: valeur
            };
        });
    };
    const updatePassword = (valeur, prop) => {

        setPassword(current => {
            return {
                ...current,
                [prop]: valeur
            };
        });
    };

    const resetHandler = () => {

        setEmail('');
        setPassword('');
        //reset la value des input dans le form
        const inputs = document.querySelectorAll('#email, #password')
        inputs.forEach(input => {
            input.value = '';
            });
            
    };

    const cancelHandler = () => {
        navigate("/login");
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        login(email, password);
        resetHandler();
    };
    // console.log(user);


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    //***************************************************************************************************************************************************************** */
    //***************************************************************************************************************************************************************** */
    // dialog window sign Up 
    const [anchorElSignUp, setAnchorElSignUp] = useState(null);

    const handleOpenNavSignUp = (event) => {
        setAnchorElSignUp(event.currentTarget);
    };

    const handleCloseNavSignUp = () => {
        setAnchorElSignUp(null);
    };
    return (
    <Box className="register" sx={{ display:"flex", flexDirection:"column"}}>
        <ThemeProvider theme={theme}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            alignItems: 'center',
            paddingTop: '150px',
        }}>
            <img className="l4Logo" src="assets/logo/looking4.png" alt="Logo Looking4" />
        </Box>

        <Container  sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            background: `rgba(255, 122, 89, 0)`,
        }}
        component="form" onSubmit={submitHandler}>
     
                <DialogWindow handleClose={handleClose}  handleDownload={handleDownload} messageErr={errMessage} open={errMessage.opened} />
                {/* onClick sign up */}
                <DialogWindowSignUp handleClose={handleCloseNavSignUp} open={anchorElSignUp}/>
                <FormControl sx={{ margin:"10px", width:{xs:'250px', lg:"525px"}}} className="input" variant="outlined" >
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <OutlinedInput sx={{borderRadius:"25px"}} id="email" onChange={e => updateEmail(e.target.value)} required />
                </FormControl>
                {/* <FormControl sx={{ margin:"10px"}} className="input">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput sx={{borderRadius:"25px"}} id="password" onChange={e => updatePassword(e.target.value)} />
                </FormControl> */}
                <FormControl sx={{ margin:"10px", width:{xs:'250px', lg:"525px"} }} className="input" variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        sx={{borderRadius:"25px"}}
                        id="password"   
                        onChange={e => updatePassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        suggested= "current-password"
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                        required
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ marginTop:"26px", display:"flex", flexDirection:"row"}}>
                    <Button sx={{marginRight:"30px",borderRadius:"25px", width:{xs:'100px', lg:"250px"}}} color="palePink" type="submit" variant="contained" onClick={cancelHandler} disableElevation>
                        <Typography sx={{fontFamily: "SegoeUi", textTransform: "capitalize", fontSize:"15px"}} className="button-register" variant='button' >Cancel</Typography>
                    </Button>
                    <Button sx={{borderRadius:"25px", width:{xs:'100px', lg:"250px"}}} color="paleGreen" type="submit" variant="contained" disableElevation>
                        <Typography sx={{fontFamily: "SegoeUi", textTransform: "capitalize", fontSize:"15px"}} className="button-register" variant='button'>Sign in</Typography>
                    </Button>
                  
                </FormControl>
        </Container>
        <Container sx={{display:"flex", flexDirection:"column", marginTop:"1rem", position:"relative", textAlign:"center",alignItems: 'center',}}>
                        <Divider color='#ffffff' sx={{width:{xs:'250px',md:'300px',lg:'450px'}, height:"1.5px"}}/> 
                        <Box sx={{display:"flex", flexDirection:"row"}}>
                            <Typography sx={{fontFamily: "SegoeUi", flexGrow:"1", fontSize:"18px", marginTop:"0.5rem", textShadow: "0.5px 0.5px #000000",}} color="#ffffff">
                                Don't have an account?
                            </Typography>
                            <Button sx={{flexGrow:"1", marginLeft:"1rem", marginTop:"0.3rem",}} onClick={handleOpenNavSignUp} variant="text">
                                <Typography sx={{fontFamily: "SegoeUi", fontSize:"16px",  textShadow: "0.5px 0.5px #000000",}}  color="skyblue" >Sign up</Typography>
                            </Button>
                            
                        </Box>
                </Container>
                <Box sx={{   
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      textAlign: 'center',
                      paddingTop: {md:"1rem",xs:"1rem" },
                      flexGrow:1,
                      position:'relative',
                      textShadow: "0.5px 0.5px #000000",
                    }}>
                    <Typography sx={{fontFamily: "SegoeUi", fontSize:"1.3rem"}} color="#ffffff">
                        For the full experience,
                    </Typography>
                    <Typography sx={{fontFamily: "SegoeUi", fontSize:"1.3rem"}} color="#ffffff">
                        download the app on your mobile devices.
                    </Typography>
                </Box>
        <Box sx={{   
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      flexGrow:1,
                      paddingTop: {md:"2rem",xs:"1rem" },
                      position:'relative',
                    }}>
                    <Box >
                        <a href="https://looking4-app.mobileappslabs.ca/download"><img className='downloadApp' src="assets/button/Download-App.png" alt="button download app store"/></a>
                    </Box>
 
                </Box>
        </ThemeProvider>
    </Box>
    )
}

export default Register;