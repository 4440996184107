import React, { useEffect, useState } from "react";

const locationContext = React.createContext({
  currentLocation: null,
  users: null,
});

const { Provider } = locationContext;

const LocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState({});
  const [fakeUsers, setFakeUsers] = useState([]);
  useEffect(() => {
    const location = async () => {
      await getLocations();
    };
    location();
  }, []);

  function getLocations() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setFakeUsers([
          {
            lat: latitude - 0.01,
            lng: longitude + 0.02,
            name: "Charles",
            img: "assets/pdpImages/Charles.jpg",
            prenium: "cadreLogin",
            eventsImg: "assets/eventsImages/Events-01.jpg",
          },
          {
            lat: latitude + 0.008,
            lng: longitude + 0.04,
            name: "Micheal",
            img: "assets/pdpImages/Micheal.jpg",
            prenium: "cadreLogin",
            eventsImg: "assets/eventsImages/Events-02.jpg",
          },
          {
            lat: latitude + 0.01,
            lng: longitude - 0.05,
            name: "Ayo",
            img: "assets/pdpImages/Ayo.jpg",
            prenium: "cadrePreniumLogin",
            eventsImg: "assets/eventsImages/Events-03.jpg",
          },
          // {
          //     lat: latitude + 0.01,
          //     lng: longitude + 0.04,
          //     name: "Chad",
          //     img: "assets/pdpImages/Chad.jpg",
          //     prenium:'cadrePreniumLogin',
          //     eventsImg:"",
          // }
        ]);
        setCurrentLocation({ lat: latitude, lng: longitude });
        resolve("");
      });
    });
  }

  return <Provider value={{ currentLocation, fakeUsers }}>{children}</Provider>;
};
export { LocationProvider, locationContext };
