import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

const DeleteWindowAll = ({
  handleCloseDeleteAll,
  open,
  btnDelete,
  deleteConfirmation,
  isConfirmed,
  updateConfirmation,
}) => {
  return (
    <>
      {open === undefined ? null : (
        <Dialog
          open={open}
          keepMounted
          onClose={handleCloseDeleteAll}
          sx={{ textAlign: "center" }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})`,
              borderRadius: "25px",
            }}
          >
            <DialogTitle
              sx={{
                fontFamily: "SegoeUi",
                fontWeight: "1000",
                margin: "10px",
                textTransform: "uppercase",
                fontSize: "2rem",
              }}
              color="whiteSmoke"
            >
              Warning
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  fontFamily: "SegoeUi",
                  color: "whitesmoke",
                  fontWeight: "1000",
                  margin: "10px",
                  fontSize: "1.5rem",
                  marginBottom: "2em",
                }}
              >
                Do you want to delete all events?
              </DialogContentText>

              <TextField
                color="white"
                id="deleteConfirmation"
                label='Type "delete" to confirm'
                type="text"
                value={deleteConfirmation}
                onChange={(e) => updateConfirmation(e.target.value)}
              />
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleCloseDeleteAll}
                sx={{ borderRadius: "25px" }}
                color="palePink"
                variant="contained"
                disableElevation
              >
                <Typography
                  sx={{
                    fontFamily: "SegoeUi",
                    fontWeight: "1000",
                    textTransform: "capitalize",
                  }}
                  color="secondary"
                  variant="button"
                >
                  Cancel
                </Typography>
              </Button>
              <Button
                onClick={btnDelete}
                sx={{ borderRadius: "25px" }}
                color="paleGreen"
                variant="contained"
                disabled={isConfirmed}
                disableElevation
              >
                <Typography
                  sx={{
                    fontFamily: "SegoeUi",
                    fontWeight: "1000",
                    textTransform: "capitalize",
                  }}
                  color="secondary"
                  variant="button"
                >
                  confirm
                </Typography>
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default DeleteWindowAll;
