import { Box, } from '@mui/material';
import GoogleMapReact from 'google-map-react';

const EventsLogin = ({defaultProps, handleApiLoaded, currentLocation, users, handleOpenNavFeature }) => {

    const AnyReactComponentUser = ({ text, img, prenium }) => 
    <div style={{color:"red", cursor: 'pointer'}} className='pinPointLogin' onClick={handleOpenNavFeature}>
        <img className={prenium} src="./assets/icon/Cadre.svg" alt='cadre'/>
        <img className='imageLogin'src={img} alt={text}/>
    </div>;

    const AnyReactComponentProfil = ({ text }) => 
    <div style={{color:"red", cursor: 'pointer'}} className='pinPointLogin'  >
        <img className='pdpLogin' src="./assets/icon/placeholder-user.png" alt={text} />
        <img className='imgPinPoint'src="./assets/icon/PinPoint.svg" alt='cadre'/>
    </div>;

    return (
        <Box sx={{ height:{md:'100vh',sm:'100vh', xs:'120vh'}, width: '100%',position:"absolute", zIndex:"0"}}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: [process.env.REACT_APP_GOOGLE_MAPS_API]}}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                // onChange={(map) => mapOnChange(map)}
                options={{
                    fullscreenControl: false,
                    zoomControl: false,
                    centerControl: true,
                    mapTypeId: "hybrid",
                }}
                center={currentLocation}
                >     
                <AnyReactComponentProfil
                    lat={currentLocation.lat}
                    lng={currentLocation.lng}
                    text={"Yurson"}
                />
                {/* si plus que 3 lag ? */}
                {users.map((user, id) => (
                    <AnyReactComponentUser
                    key={id}
                    lat={user?.lat}
                    lng={user?.lng}
                    prenium={user.prenium}
                    text={user.name}
                    img={user.eventsImg}
                    />  
                ))}


            </GoogleMapReact>


      
        
    </Box>
    )
}
export default EventsLogin;