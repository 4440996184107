// react
import { useContext, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../Context/authContext.js";
import { locationContext } from "../../Context/locationContext.js";
//style
import { ThemeProvider } from "@mui/material/styles";
import {
  Container,
  Button,
  Box,
  Typography,
  Divider,
  CardMedia,
} from "@mui/material";
import theme from "../../Styles/Styles";
import "./login.css";
//npm login
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import jwt_decode from "jwt-decode";
//dialog window import
import DialogWindowSignUp from "../../components/DialogWindow/DialogWindowSignUp";
import DialogWindow from "../../components/DialogWindow/DialogWindow";
import DialogWindowFullFeature from "../../components/DialogWindow/DialogWindowFullFeature";
// page import
import HeaderLogin from "../../components/Header/headerLogin";
import PeopleLogin from "../../components/Map/PeopleLogin";
import EventsLogin from "../../components/Map/EventsLogin";

const Login = () => {
  const {
    loginFacebook,
    loginGoogle,
    loginApple,
    errMessage,
    handleClose,
    handleDownload,
  } = useContext(authContext);
  const { currentLocation, fakeUsers } = useContext(locationContext);
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  const navigate = useNavigate();
  //***************************************************************************************************************************************************************** */
  //***************************************************************************************************************************************************************** */
  //Google Login

  const loginGoogles = useGoogleLogin({
    onSuccess: token,
  });

  async function token(tokenResponse) {
    const response = await fetch(
      "https://www.googleapis.com/oauth2/v3/userinfo?" +
        new URLSearchParams({
          access_token: tokenResponse.access_token,
        })
    );

    const data = await response.json();
    // console.log(data);
    loginGoogle(data.email, tokenResponse.access_token);
  }

  //***************************************************************************************************************************************************************** */
  //***************************************************************************************************************************************************************** */
  //Facebook Login

  const facebook = (response) => {
    // console.log(response.accessToken);
    loginFacebook(response.email, response.accessToken);
  };

  //***************************************************************************************************************************************************************** */
  //***************************************************************************************************************************************************************** */
  //Apple Login

  const initApple = () => {
    window.AppleID.auth.init({
      clientId: "com.ajis.l4oauth", // This is the service ID we created.
      scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
      redirectURI: "https://looking4.mobileappslabs.ca", // As registered along with our service ID
      state: "origin:web", // Any string of your choice that you may use for some logic. It's optional and you may omit it.
      usePopup: true, // Important if we want to capture the data apple sends on the client side.
    });
  };

  const singInApple = async () => {
    const response = await window.AppleID.auth.signIn();
    // console.log(response);
    const token = await response.authorization.id_token;
    // console.log(token);
    const decoded = await jwt_decode(token);
    console.log(decoded);
    console.log(decoded.email);
    console.log(token);
    loginApple(decoded.email, token);
  };

  //***************************************************************************************************************************************************************** */
  //***************************************************************************************************************************************************************** */
  // Email Login
  const [opened, setOpened] = useState(false);

  const handleCloseFullFeature = async () => {
    setOpened(false);
  };

  const [openedLogin, setOpenedLogin] = useState(false);

  const handleCloseLogin = async () => {
    setOpenedLogin(false);
  };
  const register = () => {
    navigate("/register");
  };

  //***************************************************************************************************************************************************************** */
  //***************************************************************************************************************************************************************** */
  //map
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);

  const defaultProps = {
    center: {
      lat: 41.890214172631346,
      lng: 12.492230897824744,
    },
    zoom: 14,
  };

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    mapRef.current = map;
    setMapReady(true);
  };

  const [mapChanged, setMapChanged] = useState(true);
  const handleMapChanged = (props) => {
    setMapChanged(props);
  };

  //***************************************************************************************************************************************************************** */
  //***************************************************************************************************************************************************************** */
  // dialog window full feature
  const [anchorElFeature, setAnchorElFeature] = useState(null);

  const handleOpenNavFeature = (event) => {
    setAnchorElFeature(event.currentTarget);
  };

  const handleCloseNavFeature = () => {
    setAnchorElFeature(null);
  };

  //***************************************************************************************************************************************************************** */
  //***************************************************************************************************************************************************************** */
  // dialog window sign Up
  const [anchorElSignUp, setAnchorElSignUp] = useState(null);

  const handleOpenNavSignUp = (event) => {
    setAnchorElSignUp(event.currentTarget);
  };

  const handleCloseNavSignUp = () => {
    setAnchorElSignUp(null);
  };


  const [isApple, setIsApple] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  function redirectToAppStore() {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
      setIsApple(true);
    } else if (userAgent.includes("android")) {
      setIsAndroid(true);
    }

  }
  const handleAppleOnClick = () => {
    window.location.href = "https://apps.apple.com/app/Looking4/1635369218";
  }

  const handleAndroidOnClick = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.ajis.looking4";
  }
  useEffect(() => {
    redirectToAppStore();
  }, []);
  

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* on error pop over */}
        <DialogWindow
          handleClose={handleClose}
          handleDownload={handleDownload}
          messageErr={errMessage}
          open={errMessage.opened}
        />
        {/* onClick sign up */}
        <DialogWindowSignUp
          handleClose={handleCloseNavSignUp}
          open={anchorElSignUp}
        />
        {/* on click login for full acces */}
        <DialogWindowFullFeature
          handleClose={handleCloseNavFeature}
          open={anchorElFeature}
        />
        <HeaderLogin
          handleMapChanged={handleMapChanged}
          mapChanged={mapChanged}
          notLoggedIn={notLoggedIn}
        />
        <Box
          className="login"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "1",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "375px",
            height: {
              xl: "90vh",
              lg: "90vh",
              md: "90vh",
              sm: "100vh",
              smd: "130vh",
              xs: "140vh",
            },
          }}
        >
          {/* Logo Looking4 */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
              paddingTop: {
                lg: "6em",
                md: "8em",
                sm: "8em",
                smd: "13em",
                xs: "13em",
              },
              position: "relative",
            }}
          >
            <CardMedia
              sx={{ height: "7em" }}
              component="img"
              image="assets/logo/looking4.png"
              alt="Logo Looking4"
            />
          </Box>

          {/* Login Bouton */}
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              alignItems: "center",
              position: "relative",
            }}
          >
            {initApple()}

            {/* Apple */}
            <Button
              sx={{
                margin: "0.7em",
                width: "300px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              color="white"
              variant="contained"
              onClick={isApple? (handleAppleOnClick): isAndroid ? (handleAndroidOnClick) : (singInApple)}
              type="submit"
            >
              
              <img
                className="login-logo apple"
                src="assets/icon/Apple.svg"
                alt="Logo Apple"
              />
              <Typography
                sx={{ fontFamily: "SegoeUi", flexGrow: "2", fontSize: "14px" }}
                color="secondary"
                variant="button"
              >
                SIGN IN WITH APPLE
              </Typography>
            </Button>

            {/* Google */}
            <Button
              sx={{
                margin: "0.7em",
                width: "300px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              color="white"
              variant="contained"
              onClick={isApple? (handleAppleOnClick): isAndroid ? (handleAndroidOnClick) : (loginGoogles)}
              type="submit"
            >
              <img
                className="login-logo"
                src="assets/icon/Google.svg"
                alt="Logo Google"
              />
              <Typography
                sx={{ fontFamily: "SegoeUi", flexGrow: "2", fontSize: "14px" }}
                color="secondary"
                variant="button"
              >
                SIGN IN WITH GMAIL
              </Typography>
            </Button>

            {/* Facebook */}
            <FacebookLogin
              appId="4980322185411629"
              autoLoad={false}
              callback={facebook}
              fields="name,email,picture"
              render={(renderProps) => (
                <Button
                  sx={{
                    margin: "0.7em",
                    width: "300px",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  color="white"
                  onClick={isApple? (handleAppleOnClick): isAndroid ? (handleAndroidOnClick) : (renderProps.onClick)}
                  variant="contained"
                  type="submit"
                >
                  <img
                    className="login-logo"
                    src="assets/icon/Facebook.svg"
                    alt="Logo facebook"
                  />
                  <Typography
                    sx={{
                      fontFamily: "SegoeUi",
                      flexGrow: "2",
                      fontSize: "14px",
                    }}
                    color="secondary"
                    variant="button"
                  >
                    SIGN IN WITH FACEBOOK
                  </Typography>
                </Button>
              )}
            />

            {/* Email */}
            <Button
              sx={{
                margin: "0.7em",
                width: "300px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              color="white"
              onClick={isApple? (handleAppleOnClick): isAndroid ? (handleAndroidOnClick) : (register)}
              variant="contained"
              type="submit"
            >
              <img
                className="login-logo"
                src="assets/icon/Email.svg"
                alt="Logo Email"
              />
              <Typography
                sx={{ fontFamily: "SegoeUi", flexGrow: "2", fontSize: "14px" }}
                className="link"
                color="secondary"
                variant="button"
              >
                SIGN IN WITH EMAIL
              </Typography>
            </Button>
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
              position: "relative",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Divider
              color="#ffffff"
              sx={{
                width: { xs: "250px", md: "300px", lg: "300px" },
                height: "1.5px",
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                sx={{
                  fontFamily: "SegoeUi",
                  flexGrow: "1",
                  fontSize: "18px",
                  marginTop: "0.5em",
                  textShadow: "0.5px 0.5px #000000",
                }}
                color="#ffffff"
              >
                Don't have an account?
              </Typography>
              <Button
                sx={{ flexGrow: "1", marginLeft: "1em", marginTop: "0.3em" }}
                onClick={handleOpenNavSignUp}
                variant="text"
              >
                <Typography
                  sx={{
                    fontFamily: "SegoeUi",
                    fontSize: "16px",
                    textShadow: "0.5px 0.5px #000000",
                  }}
                  color="skyblue"
                >
                  Sign up
                </Typography>
              </Button>
            </Box>
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1em",
              position: "relative",
            }}
          >
            <CardMedia
              component="iframe"
              src="https://www.youtube.com/embed/yj2DI8nHcjI"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
              title="Embedded youtube"
              sx={{
                aspectRatio: "16/9",
                width: "80%",
                left: "10%",
                top: "0",
                position: "relative",
              }}
            />
          </Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              paddingTop: { md: "1em", xs: "1em" },
              flexGrow: 1,
              position: "relative",
              textShadow: "0.5px 0.5px #000000",
            }}
          >
            {/* corriger ?? */}
            <Typography
              sx={{
                fontFamily: "SegoeUi",
                position: "relative",
                fontSize: "1em",
                paddingRight:"2em",
                paddingLeft:"2em",
              }}
              color="#ffffff"
            >
              For the full experience, download the app on your mobile devices.
            </Typography>
            {/* <Typography
              sx={{
                fontFamily: "SegoeUi",
                position: "relative",
                fontSize: "1em",
              }}
              color="#ffffff"
            >
              download the app on your mobile devices.
            </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexGrow: 1,
              paddingTop: { md: "1em", xs: "1em" },
              position: "relative",
            }}
          >
            <Box>
              <a href="https://looking4-app.mobileappslabs.ca/download">
                <img
                  className="downloadApp"
                  src="assets/button/Download-App-Horiz.png"
                  alt="button download app store"
                />
              </a>
            </Box>
          </Box>
        </Box>
        {/* Google Map */}

        {mapChanged !== false ? (
          <PeopleLogin
            users={fakeUsers}
            currentLocation={currentLocation}
            defaultProps={defaultProps}
            handleApiLoaded={handleApiLoaded}
            handleOpenNavFeature={handleOpenNavFeature}
          />
        ) : (
          <EventsLogin
            users={fakeUsers}
            currentLocation={currentLocation}
            defaultProps={defaultProps}
            handleApiLoaded={handleApiLoaded}
            handleOpenNavFeature={handleOpenNavFeature}
          />
        )}
      </ThemeProvider>
    </>
  );
};
export default Login;
