
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const DialogWindowFullFeature = ({handleClose, open }) => {


    return(
        <>
        {open === undefined ? (null) :
        
        (<Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{textAlign:"center"}}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                    }}
            >
                <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-evenly", backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})`, borderRadius:"25px"}}>
                    <DialogTitle sx={{margin:"2rem"}} color="whitesmoke" ><img className="DialogLogin" src="assets/logo/looking4.png" alt="Logo Looking4" /></DialogTitle>
                    <DialogContent  >
                        <DialogContentText sx={{fontFamily: "SegoeUi", color:"whitesmoke", fontWeight:"1000", margin:"2rem", fontSize:"2rem"}} id="alert-dialog-slide-description">
                        Login to get full acces.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{display:"flex", justifyContent:"center", margin:"2rem"}} >
                        {/* <Button onClick={handleClose} sx={{ borderRadius:"25px"}} color="palePink" variant="contained" disableElevation>
                            <Typography sx={{fontFamily: "SegoeUi", fontWeight:"1000", textTransform: "capitalize",}} color="secondary" variant='button'>Cancel</Typography>
                        </Button> */}
                        {/* <Button onClick={handleDownload} sx={{borderRadius:"25px"}} color="paleGreen" variant="contained" disableElevation>
                            <Typography sx={{fontFamily: "SegoeUi", fontWeight:"1000", textTransform: "capitalize",}} color="secondary" variant='button'>Download</Typography>
                        </Button>     */}
              
                            <Box >
                                <a href="https://looking4-app.mobileappslabs.ca/download"><img className='downloadApp' src="assets/button/Download-App.png" alt="button download app store"/></a>
                            </Box>
                      
                    </DialogActions> 
                </Box>
            </Dialog>)}
            
        </>
    )
}
export default DialogWindowFullFeature;