import React, { useState } from "react";

const authContext = React.createContext({
    login: async (email, password) => {},
    loginGoogle: async (email, token) => {},
    loginFacebook: async (email, token) => {},
    loginApple: async (email, token) => {},
    logout: async () => {},
    handleClose: async () => {},
    handleDownload: async () => {},
    user: null,
    isAuth: false,
    errMessage: {opened: false, msg: "err", title: "err"},
});

const { Provider } = authContext;

const AuthProvider = ({children}) => {

    const [user, setUser] = useState(null);
    const [errMessage, setErrMessage] = useState([{opened: false, msg: "je suis ferm/", title: "suis-je ferm/" }]);

    //fonction qui verifie pdp sinon placeholder transformer l<image en base64
    //{ isNewAccount: true }
      const handleClose =  async () => {
        setErrMessage({opened:false});
    };

    const handleDownload = () => {
        // telechargement de l>app
    };

    const login = async (email, password) => {
        const response = await fetch("https://looking4-api.mobileappslabs.ca/auth/signin", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                email: email['undefined'],
                pwd: password['undefined'],
                mediaChecksum: [],
                localDay:"",
            })
        })
        try{
            if(response.status === 200) 
            {
                const data = await response.json();
                if(data.isNewAccount === true ){
                    setUser(null);
                    setErrMessage({opened: true, msg: "Download or use the mobile app to create your account.", title: "Can't Sign in?"});
                } else {
                    setUser(data);
                }
            } else if (response.status === 401){
                //bad username password
                setErrMessage({opened: true, msg: "Your username or your password is wrong please try again.", title: "Username or password is wrong."});
            } else {
                //erreur communication serveur
                setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            }
        }
        catch(ex){
            setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            // console.log(ex.message);
            // console.log(ex.stack);
        }
    }

    const loginGoogle= async (email, token) => {
        const response = await fetch("https://looking4-api.mobileappslabs.ca/auth/signinOAuth", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                scheme:"0",
                email: email,
                token: token,
                mediaChecksum: [],
                localDay:"",
            })
        })
        try{
            if(response.status === 200) 
            {
                const data = await response.json();
                if(data.isNewAccount === true ){
                    setUser(null);
                    setErrMessage({opened: true, msg: "Download or use the mobile app to create your account.", title: "Can't Sign in?"});
                } else {
                    setUser(data);
                }
            } else if (response.status === 401){
                //bad username password
                setErrMessage({opened: true, msg: "Your username or your password is wrong please try again.", title: "Username or password is wrong."});
            } else {
                //erreur communication serveur
                setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            }
        }
        catch(ex){
            setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            // console.log(ex.message);
            // console.log(ex.stack);
        }
    };

    const loginFacebook = async (email, token) => {
        const response = await fetch("https://looking4-api.mobileappslabs.ca/auth/signinOAuth", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                scheme:"1",
                email: email,
                token: token,
                mediaChecksum: [],
                localDay:"",
            })
        })
        try{
            if(response.status === 200) 
            {
                const data = await response.json();
                if(data.isNewAccount === true ){
                    setUser(null);
                    setErrMessage({opened: true, msg: "Download or use the mobile app to create your account.", title: "Can't Sign in?"});
                } else {
                    setUser(data);
                }
            } else if (response.status === 401){
                //bad username password
                setErrMessage({opened: true, msg: "Your username or your password is wrong please try again.", title: "Username or password is wrong."});
            } else {
                //erreur communication serveur
                setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            }
        }
        catch(ex){
            setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            // console.log(ex.message);
            // console.log(ex.stack);
        }
    };

    const loginApple = async (email, token) => {
        console.log(email, token);
        const response = await fetch("https://looking4-api.mobileappslabs.ca/auth/signinOAuth", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                scheme:"2",
                email: email,
                token: token,
                mediaChecksum: [],
                localDay:"",
            })
        })
        try{
            if(response.status === 200) 
            {
                const data = await response.json();
                if(data.isNewAccount === true ){
                    setUser(null);
                    setErrMessage({opened: true, msg: "Download or use the mobile app to create your account.", title: "Can't Sign in?"});
                } else {
                    setUser(data);
                }
            } else if (response.status === 401){
                //bad username password
                setErrMessage({opened: true, msg: "Your username or your password is wrong please try again.", title: "Username or password is wrong."});
            } else {
                //erreur communication serveur
                setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            }
        }
        catch(ex){
            setErrMessage({opened: true, msg: "The communication with the server as failed. Refresh and try again.", title: "Server communication failed."});
            // console.log(ex.message);
            // console.log(ex.stack);
        }
    };

    const logout = async () => {
        setUser(null);
    };
    

    return (
        <Provider value={{ user, errMessage, login, logout, loginFacebook, loginGoogle, loginApple, handleClose, handleDownload }}>{children}</Provider>
    );

};
export { AuthProvider, authContext};