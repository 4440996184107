//style
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography} from '@mui/material';

const Loading = () => {

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center',marginTop:"1rem",marginBottom:"1rem"}}> 
            <CircularProgress sx={{marginBottom:"1rem"}} disableShrink color="primary" />
            <Typography class="loading">Loading...</Typography>
        </Box>
    )
}

export default Loading;