//react
import { useState, useEffect } from "react";
//style
import {
  Button,
  Box,
  Typography,
  Tooltip,
  ThemeProvider,
  Menu,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import theme from "../../Styles/Styles";

const VisibilityForm = ({
  handleOpenVisibilityMenu,
  anchorElVisibility,
  handleCloseVisibilityMenu,
  visibilitys,
  event,
  index,
  updateEventVisibility,
}) => {
  const [value, setValue] = useState(visibilitys[0].value);
  const handleRadioGroupe = async (e) => {
    if (e.target.checked) {
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    updateEventVisibility(value, index);
    handleColor(value);
  }, [value]);

  const [color, setColor] = useState("visibilityRed");
  const handleColor = (props) => {
    // console.log(props);
    if (props === "0" || props === "1") {
      setColor("visibilityRed");
      // console.log("red");
    } else if (props === "4") {
      setColor("visibilityGreen");
      // console.log("green");
    } else if (props === "2" || props === "3" || props === "5") {
      setColor("visibilityYellow");
      // console.log("yellow");
    } else {
      setColor("visibilityRed");
      // console.log("default");
    }
  };

  return (
    // sx={{position:"absolute", zIndex:"1", top:"6%", left:"48%"}}
    <Box sx={{}}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: { marginTop: "10px", display: "flex" } }}>
          <Tooltip title="Event visibility">
            {event.visibility !== undefined ? (
              <Button onClick={handleOpenVisibilityMenu} disableElevation>
                <img className={color} src="assets/icon/Lock.svg" alt="Add" />
              </Button>
            ) : (
              <Button onClick={handleOpenVisibilityMenu} disableElevation>
                <img
                  className={event.visibility.className}
                  src="assets/icon/Lock.svg"
                  alt="Add"
                />
              </Button>
            )}
          </Tooltip>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElVisibility}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElVisibility)}
            onClose={handleCloseVisibilityMenu}
            elevation={4}
          >
            <Typography
              color="#886BBC"
              sx={{
                fontFamily: "SegoeUi",
                margin: "1rem",
                fontStyle: "italic",
              }}
              textAlign="center"
            >
              Choose privacy
            </Typography>
            <RadioGroup
              name="controlled-radio-buttons-group"
              value={event.visibility}
              onChange={(e) => handleRadioGroupe(e)}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              {visibilitys !== undefined
                ? visibilitys.map((visibility, i) => (
                    <MenuItem
                      key={i}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        color="#886BBC"
                        sx={{
                          fontFamily: "SegoeUi",
                          margin: "1rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {visibility.name}
                      </Typography>
                      <FormControlLabel
                        value={visibility.value}
                        control={
                          <Radio
                            sx={{
                              color: "#707070",
                              "&.Mui-checked": { color: "#58b100" },
                            }}
                          />
                        }
                      />
                    </MenuItem>
                  ))
                : null}
            </RadioGroup>
          </Menu>
        </Box>
      </ThemeProvider>
    </Box>
  );
};
export default VisibilityForm;
