
import { Box, } from '@mui/material';
import GoogleMapReact from 'google-map-react';

const PeopleLogin = ({defaultProps, handleApiLoaded, currentLocation, users, preniums, handleOpenNavFeature }) => {

    const AnyReactComponentUser = ({ text, img, prenium }) => 
    <div style={{color:"red", cursor: 'pointer'}} className='pinPointLogin' onClick={handleOpenNavFeature}>
        <img className={prenium} src="./assets/icon/Cadre.svg" alt='cadre'/>
        <img className='imageLogin'src={img} alt={text}/>
    </div>;

    const AnyReactComponentProfil = ({ text }) => 
    <div style={{color:"red", cursor: 'pointer'}} className='pinPointLogin'  >
        <img className='pdpLogin' src="./assets/icon/placeholder-user.png" alt={text} />
        <img className='imgPinPoint'src="./assets/icon/PinPoint.svg" alt='cadre'/>
    </div>;


    return (
        <Box sx={{ height: {md:'100vh',sm:'100vh', xs:'120vh'}, width: '100%',position:"absolute", zIndex:"0"}}>
            {currentLocation !== undefined? 
            (
                <GoogleMapReact
                bootstrapURLKeys={{ key: [process.env.REACT_APP_GOOGLE_MAPS_API]}}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                // onChange={(map) => mapOnChange(map)}
                options={{
                    fullscreenControl: false,
                    zoomControl: false,
                    styles: require('../../data/google_map_style_people.json'),
                    centerControl: true,
                }}
                center={currentLocation}
                >     
                <AnyReactComponentProfil
                    lat={currentLocation.lat}
                    lng={currentLocation.lng}
                    text={"Yurson"}
                />
                
                {users.map((user, id) => (
                    <AnyReactComponentUser
                    key={id}
                    lat={user?.lat}
                    lng={user?.lng}
                    text={user.name}
                    prenium={user.prenium}
                    img={user.img}
                    />
                ))}


            </GoogleMapReact>
            )
            : 
            (<Box>Loading</Box>)}
          


      
        
    </Box>
    )
}
export default PeopleLogin;