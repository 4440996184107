
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const DialogWindow = ({handleClose, messageErr, open }) => {


    return(
        <>
        {open === undefined ? (null) :
        
        (<Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{textAlign:"center"}}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                    }}
            >
                <Box sx={{backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})`, borderRadius:"25px"}}>
                    <DialogTitle sx={{fontFamily: "SegoeUi", fontWeight:"1000", margin:"10px", textTransform:"uppercase"}} color="whitesmoke" >{messageErr.title}</DialogTitle>
                    <DialogContent  >
                        <DialogContentText sx={{fontFamily: "SegoeUi", color:"whitesmoke", fontWeight:"1000", margin:"10px"}} id="alert-dialog-slide-description">
                            {messageErr.msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display:"flex", justifyContent:"center"}} >
                        {/* <Button onClick={handleClose} sx={{ borderRadius:"25px"}} color="palePink" variant="contained" disableElevation>
                            <Typography sx={{fontFamily: "SegoeUi", fontWeight:"1000", textTransform: "capitalize",}} color="secondary" variant='button'>Cancel</Typography>
                        </Button> */}
                        {/* <Button onClick={handleDownload} sx={{borderRadius:"25px"}} color="paleGreen" variant="contained" disableElevation>
                            <Typography sx={{fontFamily: "SegoeUi", fontWeight:"1000", textTransform: "capitalize",}} color="secondary" variant='button'>Download</Typography>
                        </Button>     */}
                        <Box sx={{   
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            margin:"10px"
                            }}>
                            <Box >
                                <a href="https://looking4-app.mobileappslabs.ca/download"><img className='downloadApp' src="assets/button/Download-App.png" alt="button download app store"/></a>
                            </Box>
                        </Box>
                    </DialogActions> 
                </Box>
            </Dialog>)}
            
        </>
    )
}
export default DialogWindow;