//react
import React, { useState } from "react";
//MUI form component
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
//style
import {
  Box,
  Button,
  FormControl,
  Tooltip,
  TextField,
  Autocomplete,
  Typography,
} from "@mui/material";
//form component
import VisibilityForm from "../VisibilityForm/VisibilityForm";
import PhotoFormWindow from "../DialogWindow/PhotoFormWindow";
import DeleteWindow from "../DialogWindow/DeleteWindow";
import DescriptionWindow from "../DialogWindow/DescriptionWindow";
const FormAdd = ({
  getEvent,
  onClickShowMap,
  onDeletedBtnClick,
  onDuplicateBtnClick,
  event,
  index,
  updateEvent,
  updateEventVisibility,
  handleCloseDelete,
  handleOpenDelete,
  isOpenDelete,
  deleteMsg,
  onAddPhoto,
  updatePhoto,
  updateEventDescription,
}) => {

  const [eventName, setEventName] = useState(event.eventName);
  const [locationLng, setLocationLng] = useState(event.locationLng);
  const [locationLat, setLocationLat] = useState(event.locationLat);
  const [locationName, setLocationName] = useState(event.locationName)


  const [anchorElVisibility, setAnchorElVisibility] = useState(null);


  const handleOpenVisibilityMenu = (event) => {
    setAnchorElVisibility(event.currentTarget);
  };

  const handleCloseVisibilityMenu = () => {
    setAnchorElVisibility(null);
  };

  const [isOpenPhoto, setIsOpenPhoto] = useState(false);

  const handleOpenPhoto = (eventId) => {
    console.log(eventId);
    setIsOpenPhoto(true);
    getEvent(eventId, index);
  };
  const handleClosePhoto = async () => {
    setIsOpenPhoto(false);
  };
  const [isOpenDescription, setIsOpenDescription] = useState(false);

  const handleOpenDescription = () => {
    setIsOpenDescription(true);
  };
  const handleCloseDescription = async () => {
    setIsOpenDescription(false);
  };
  //(0 = invisible, 1 = private, 2 = followers only, 3 = on invitiation, 4 = open to all)
  const visibilitys = [
    {
      name: "Invisible",
      value: "0",
      className: "visibilityRed",
    },
    {
      name: "Private",
      value: "1",
      className: "visibilityRed",
    },
    {
      name: "Followers only",
      value: "2",
      className: "visibilityYellow",
    },
    {
      name: "On invitiation",
      value: "3",
      className: "visibilityYellow",
    },
    {
      name: "By type only",
      value: "5",
      className: "visibilityYellow",
    },
    {
      name: "Open to all",
      value: "4",
      className: "visibilityGreen",
    },
  ];
  // 0 = invisible, 1 = private, 2 = followers only, 3 = on invitiation, 4 = open to all, 5 = By type only

  const optionEventsTypes = [
    { type: "All", value: "All" },
    { type: "Friendship", value: "Friendship" },
    { type: "Love", value: "Love" },
    { type: "Fun", value: "Fun" },
    { type: "Discovery", value: "Discovery" },
    { type: "Party", value: "Party" },
  ];
  // console.log(event);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: {xl:"space-between", xs:"center"},
        flexWrap:{xl:"nowrap", xs:"wrap"},
        marginBottom: "1rem",

      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* Duplication Button */}
        <FormControl
          sx={{
            marginTop: "8px",
            margin: "2px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Tooltip title="Duplicate event">
            <Button onClick={() => onDuplicateBtnClick(event)} disableElevation>
              <img
                className="logo-dark"
                src="assets/icon/Event Add.svg"
                alt="Add"
              />
            </Button>
          </Tooltip>
        </FormControl>

        {/* dot state off save or not */}
        <FormControl
          sx={{
            marginTop: "10px",
            marginBottom: "2px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Tooltip title={
                event.publish === true ? "Saved" : "Unsaved"
              }>
          <Button  disableElevation>
            <img
              className={
                event.publish === true ? "img-dot-true" : "img-dot-false"
              }
              src="assets/icon/Dot.svg"
              alt="Add"
            />
          </Button>
          </Tooltip>
        </FormControl>

        {/* Event Name... */}
        <FormControl
          sx={{ marginTop: "8px", margin: "2px" }}
          variant="outlined"
        >
          <TextField
            id="eventName"
            label="Event Name"
            placeholder="Event Name..."
            value={eventName}
            // onChange={(e) => updateEvent("eventName", e.target.value, index)}
            onChange={(e) => setEventName(e.target.value)}
            onBlur={(e) => updateEvent("eventName", e.target.value, index)}
          />
        </FormControl>

        {/* Event type... default or type*/}

        <FormControl sx={{ marginTop: "8px", margin: "2px", width: "200px" }}>
          <Autocomplete
            freeSolo
            autoHighlight
            inputValue={event.eventType}
            onInputChange={(e, newValue) => {
              updateEvent("eventType", newValue, index);
            }}
            options={optionEventsTypes}
            getOptionLabel={(optionEventsTypes) => optionEventsTypes.type}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.value === "All" ? (
                  <img
                    loading="lazy"
                    width="20"
                    src="assets/icon/multiplication-1.svg"
                    alt="Interest"
                    className="Form-option-icon"
                  />
                ) : (
                  <img
                    loading="lazy"
                    width="20"
                    src="assets/icon/Interest.svg"
                    alt="Interest"
                    className="Form-option-icon"
                  />
                )}
                {option.value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Event type..."
                InputProps={{
                  ...params.InputProps,
                  // type: "search",
                  autoComplete: "new-password",
                }}
              />
            )}
          />
        </FormControl>

        {/* description max 500 charactere */}
        <Box
          sx={{ marginTop: "8px", margin: "2px" }}
          variant="outlined"
        >

          <TextField
            id="description"
            label="Description"
            // multiline
            // rows={1}
            value={event.description}
            placeholder="Description..."
            onClick={handleOpenDescription}
          />
          
        </Box>
        <DescriptionWindow
            updateEventDescription={updateEventDescription}
            handleClose={handleCloseDescription}
            open={isOpenDescription}
            event={event}
            index={index}
        />
        {/* YY/MM/DD date debut / date fin */}
        <Box
          sx={{ minWidth: 150, maxWidth: 250, marginTop: "8px", margin: "2px" }}
          components={["DateTimePicker"]}
        >
          <FormControl components={["DateTimePicker"]} fullWidth>
            <DateTimePicker
              id="startingDate"
              label="Start Date"
              value={moment(event.startingDate)}
              onChange={(newValue) =>
                updateEvent("startingDate", newValue, index)
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{ minWidth: 150, maxWidth: 250, marginTop: "8px", margin: "2px" }}
          components={["DateTimePicker"]}
        >
          <FormControl fullWidth>
            <DateTimePicker
              id="endingDate"
              label="End Date"
              value={moment(event.endingDate)}
              onChange={(newValue) =>
                updateEvent("endingDate", newValue, index)
              }
            />
          </FormControl>
        </Box>

        {/* Longitude latitude */}
        <FormControl sx={{ marginTop: "8px", margin: "2px" }}>
          <TextField
            id="locationLng"
            label="Location Longitude"
            type="number"
            value={locationLng}
            placeholder="41.89"
            // onChange={(e) => updateEvent("locationLng", e.target.value, index)}
            onChange={(e) => setLocationLng(e.target.value)}
            onBlur={(e) => updateEvent("locationLng", e.target.value, index)}
          />
        </FormControl>
        <FormControl sx={{ marginTop: "8px", margin: "2px" }}>
          <TextField
            id="locationLat"
            label="Location Latitude"
            type="number"
            value={locationLat}
            placeholder="-20.54"
            // onChange={(e) => updateEvent("locationLat", e.target.value, index)}
            onChange={(e) => setLocationLat(e.target.value)}
            onBlur={(e) => updateEvent("locationLat", e.target.value, index)}
          />
        </FormControl>

        {/* Location name */}
        <FormControl sx={{ marginTop: "8px", margin: "2px" }}>
          <TextField
            id="locationName"
            label="Location name"
            value={locationName}
            placeholder="Location Name..."
            // onChange={(e) => updateEvent("locationName", e.target.value, index)}
            onChange={(e) => setLocationName(e.target.value)}
            onBlur={(e) => updateEvent("locationName", e.target.value, index)}
          />
        </FormControl>

        {/* icon Emplacement button */}
        <FormControl sx={{ marginTop: "10px", display: "flex" }}>
          <Tooltip title="Chose location">
            <Button
              onClick={() => onClickShowMap(index)}
              type="submit"
              disableElevation
            >
              <img
                className="logo-dark-emplacement"
                src="assets/icon/Center_Map.svg"
                alt="Add"
              />
            </Button>
          </Tooltip>
        </FormControl>

        {/* icon Photo button */}
        <Box sx={{ marginTop: "0px", display: "flex" }}>
          <Tooltip title="Event photo">
            <Button
              onClick={() => handleOpenPhoto(event.eventId, index)}
              type="submit"
              disableElevation
            >
              <img
                className="logo-dark"
                src="assets/icon/Photo-Cutout.svg"
                alt="Add"
              />
            </Button>
          </Tooltip>
          <PhotoFormWindow
            handleClose={handleClosePhoto}
            open={isOpenPhoto}
            updatePhoto={updatePhoto}
            event={event}
            onAddPhoto={onAddPhoto}
            index={index}
          />
        </Box>

        {/* icon LVL Visibility button */}

        <VisibilityForm
          updateEventVisibility={updateEventVisibility}
          index={index}
          visibilitys={visibilitys}
          event={event}
          handleOpenVisibilityMenu={handleOpenVisibilityMenu}
          handleCloseVisibilityMenu={handleCloseVisibilityMenu}
          anchorElVisibility={anchorElVisibility}
        />

        {/* icon Delete button */}
        <FormControl sx={{ marginTop: "15px", display: "flex" }}>
          <Tooltip title="Delete event">
            <Button
              onClick={handleOpenDelete}
              type="submit"
              disableElevation
            >
              <img
                className="logo-trash"
                src="assets/icon/Trash.svg"
                alt="Add"
              />
            </Button>
          </Tooltip>
        </FormControl>
        <DeleteWindow
          handleClose={handleCloseDelete}
          open={isOpenDelete}
          btnDelete={() => onDeletedBtnClick(index, event.eventId)}
          deleteMsg={deleteMsg}
        />
      </LocalizationProvider>
    </Box>
  );
};
export default FormAdd;
