//react
import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { userContext } from "../../Context/userContext.js";
import { locationContext } from "../../Context/locationContext.js";
//style
import { Box } from "@mui/material";
import "./GoogleMap.css";
//Dialog
import DialogWindowFullFeature from "../../components/DialogWindow/DialogWindowFullFeature";
import Filter from "../../components/Filter/Filter.js";

const GoogleMapEvent = () => {
  // google map marker
  const AnyReactComponent = ({ text, base64 }) => (
    <div
      style={{ color: "red", cursor: "pointer" }}
      className="pinPoint"
      onClick={handleOpenNavFeature}
    >
      <img className="cadre" src="./assets/icon/Cadre.svg" alt="cadre" />
      <img
        className="image"
        src={`data:image/jpeg;base64,${base64}`}
        alt={text}
      />
    </div>
  );

  const PlaceholderComponent = ({ text, base64 }) => (
    <div
      style={{ color: "red", cursor: "pointer" }}
      className="pinPoint"
      onClick={handleOpenNavFeature}
    >
      <img className="cadre" src="./assets/icon/Cadre.svg" alt="cadre" />
      <img
        className="image"
        src="./assets/icon/placeholder-user.png"
        alt={text}
      />
    </div>
  );

  const AnyReactComponentProfil = ({ text, base64 }) => (
    <div style={{ color: "red", cursor: "pointer" }} className="pinPoint">
      <img
        className="pdp"
        src={`data:image/jpeg;base64,${base64}`}
        alt={text}
      />
      <img
        className="imgPinPoint"
        src="./assets/icon/PinPoint.svg"
        alt="cadre"
      />
    </div>
  );

  // context
  const { user } = useContext(userContext);
  const { currentLocation } = useContext(locationContext);

  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  // if user undefined load else show

  const [peoples, setPeoples] = useState([]);

  //status: 0-2 [0=online, 1=int. only, 2=invisible]
  const [status, setStatus] = useState("");
  // distance visible en latitude
  const [latDeg, setLatDeg] = useState("100");
  // distance visible en longitude
  const [lngDeg, setLngDeg] = useState("100");
  // 0-1 [0=people, 1=event]
  const [type, setType] = useState("0");

  // interest
  const [int, setInt] = useState("all");
  // 0-2 [0=all, 1=default, 2=custom]
  const [intType, setIntType] = useState("0");

  const defaultInterest = [
    {
      name: "all",
      type: "0",
      img: "assets/icon/multiplication-1.svg",
    },
    {
      name: "date",
      type: "1",
      img: "assets/icon/Interest.svg",
    },
    {
      name: "friendship",
      type: "1",
      img: "assets/icon/Interest.svg",
    },
    {
      name: "love",
      type: "1",
      img: "assets/icon/Interest.svg",
    },
    {
      name: "fun",
      type: "1",
      img: "assets/icon/Interest.svg",
    },
  ];

  const defaultProps = {
    center: {
      lat: 41.890214172631346,
      lng: 12.492230897824744,
    },
    zoom: 15,
  };

  useEffect(() => {
    if (user !== null) {
      setStatus(user.user.status);
      peopleProximity();
    }
  }, [user]);

  const peopleProximity = async () => {
    const response = await fetch(
      "https://looking4-api.mobileappslabs.ca/map/getLocations",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: user.token,
          userid: user.user.id,
        },
        body: new URLSearchParams({
          status: status,
          lat: currentLocation.lat,
          lng: currentLocation.lng,
          latDeg: latDeg,
          lngDeg: lngDeg,
          type: type,
          int: int,
          intType: intType,
        }),
      }
    );

    const data = await response.json();
    // console.log(data);
    setPeoples(data.data);
    console.log(peoples);
  };

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    mapRef.current = map;
    setMapReady(true);
    peopleProximity();
  };
  // const [mapZoom, setMapZoom] = useState(defaultProps.zoom);
  const mapOnChange = (map) => {
    // console.log(map);
    // if(map.zoom !== mapZoom) {
    //   peopleProximity();
    //   console.log(map.zoom);
    // }
  };

  //*********************************************************************** */
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [value, setValue] = useState("Interest");

  const handleSearchName = (event) => {
    setValue(event.target.value);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorElFeature, setAnchorElFeature] = useState(null);

  const handleOpenNavFeature = (event) => {
    setAnchorElFeature(event.currentTarget);
  };

  const handleCloseNavFeature = () => {
    setAnchorElFeature(null);
  };

  //handleOpenUserMenu, anchorElUser, handleCloseUserMenu
  return (
    <>
      {/* <Filter handleOpenNavMenu={handleOpenNavMenu} handleCloseNavMenu={handleCloseNavMenu} anchorElNav={anchorElNav} value={value} handleSearchName={handleSearchName} 
              defaultInterest={defaultInterest} /> */}

      <Box
        sx={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          zIndex: "0",
        }}
      >
        <DialogWindowFullFeature
          handleClose={handleCloseNavFeature}
          open={anchorElFeature}
          handleCloseNavFeature
        />
        {peoples !== undefined ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: [process.env.REACT_APP_GOOGLE_MAPS_API],
              libraries: ["places"],
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            // onChange={(map) => console.log('Map moved', map)}
            // onChange={(map) => mapOnChange(map)}
            options={{
              fullscreenControl: false,
              zoomControl: false,
              styles: require("../../data/google_map_style_people.json"),
              centerControl: true,
            }}
            center={currentLocation}
          >
            {/* <Filter handleOpenNavMenu={handleOpenNavMenu} anchorElNav={anchorElNav} handleCloseNavMenu={handleCloseNavMenu} /> */}

            {peoples.map((people, index) =>
              people.photo !== "" ? (
                <AnyReactComponent
                  key={index}
                  lat={people?.lat}
                  lng={people?.lng}
                  text={people?.name}
                  base64={people?.photo}
                />
              ) : (
                <PlaceholderComponent
                  key={index}
                  lat={people?.lat}
                  lng={people?.lng}
                  text={people?.name}
                />
              )
            )}

            <AnyReactComponentProfil
              lat={currentLocation.lat}
              lng={currentLocation.lng}
              text={user?.user?.name}
              base64={user?.user?.profile_image}
            />
          </GoogleMapReact>
        ) : (
          <h1>Loading</h1>
        )}
      </Box>
    </>
  );
};

export default GoogleMapEvent;
