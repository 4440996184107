import Layout from "../Layout/Layout";
import { UserProvider } from "../../Context/userContext";
const AuthLayout = () => {

    return(
        <div >
            <UserProvider>
                <Layout/>
            </UserProvider>
        </div>
    );
};
export default AuthLayout;