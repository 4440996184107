import { useContext, useState, useRef, useEffect } from "react";
import { Box ,Typography, CardMedia } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import HeaderLogin from "../../components/Header/headerLogin";
import './DeleteAccount.css';
import theme from '../../Styles/Styles';


const DeleteAccount = () => {
    const [notLoggedIn, setNotLoggedIn] = useState(true);
    const [mapChanged, setMapChanged] = useState(true);
    const handleMapChanged = (props) => {
      setMapChanged(props);
    };


    return(
        <Box className="deleteAccount" sx={{ display:"flex", flexDirection:"column"}}>
            <ThemeProvider theme={theme}>
            <HeaderLogin
                handleMapChanged={handleMapChanged}
                mapChanged={mapChanged}
                notLoggedIn={notLoggedIn}
            />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop:"10rem",
                
            }} component="article">

            
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                alignItems: 'flex-start',
                // marginBottom:"3rem"
            }}>
                <Typography variant="h4" component="h4"
                    sx={{
                        marginBottom: '1rem',
                    }}
                >
                    Delete Your Looking4 Account
                </Typography>
                <Typography variant="h5" component="h5"
                    sx={{
                        marginBottom: '2rem',
                    }}
                >
                    Delete your account
                </Typography>

                <Typography variant="body1" component="p"
                    sx={{

                    }}
                >
                    First download and install the Looking 4 app (Installer l'application looking 4)
                </Typography>
                <Typography variant="body1" component="p"
                    sx={{

                    }}
                >
                    <a href="https://looking4-app.mobileappslabs.ca/download">[LINK TO DOWNLOAD]</a>
                </Typography>

                <Box component="ol" 
                    sx={{
                        marginTop:"1rem",
                        marginLeft:"3rem",
                        
                    }}>
                    <Typography variant="body1" component="li">Then, on the main page, click on your icon at the top left ( Ensuite, dans le menu principal, cliquer sur votre portrait en haut à gauche)</Typography>
                    <Typography variant="body1" component="li">After, go into your account by clicking on the cog (Ensuite, allez dans votre compte en cliquant sur la roue dentée)</Typography>
                    <Typography variant="body1" component="li">Now click on the top right cog to go into your settings (maintenant, cliquez sur la roue dentée en haut a droite pour aller dans vos options)</Typography>
                    <Typography variant="body1" component="li"> In your settings, click on the "Delete account" button. (Dans vos options, cliquez sur le bouton "Supprimer le compte")</Typography>
                    <Typography variant="body1" component="li">A confirmation popup will appear, type in "delete" to confirm (Un dialogue apparaitra et vous demandera d'écrire "delete" pour confirmer)</Typography>
                    <Typography variant="body1" component="li">Now press the "delete" button and you are done (Maintenant cliquez sur le bouton "supprimez" et tout est complet)</Typography>
                </Box>
               
            <Box sx={{
                display: "flex",
                flexWrap:"wrap",
                gap:"5rem",
                marginTop:"3rem"
            }}>
                    <img
                    component="img"          
                    className="instructionImg"
                    src="assets/deleteAccount/Instruction.jpg"
                    alt="instructionImg 1"
 
                    />
                    
                    <img
                    component="img"            
                    className="instructionImg"
                    src="assets/deleteAccount/Instruction2.jpg"
                    alt="instructionImg 2"/>
                    <img  
                    component="img"          
                    className="instructionImg"
                    src="assets/deleteAccount/Instruction3.jpg"
                    alt="instructionImg 3"/>

            </Box>
            <Box sx={{
                display: "flex",
                flexWrap:"wrap",
                gap:"5rem",
                marginTop:"1rem"
            }}>
                   
                    <img
                    component="img"            
                    className="instructionImg"
                    src="assets/deleteAccount/Instruction4.jpg"
                    alt="instructionImg 4"/>

                    <img 
                    component="img"        
                    className="instructionImg"
                    src="assets/deleteAccount/Instruction5.jpg"
                    alt="instructionImg 5"/>
                    <img
                    component="img"            
                    className="instructionImg"
                    src="assets/deleteAccount/Instruction6.jpg"
                    alt="instructionImg 6"/>
            </Box>
            </Box>
         
            </Box>
            </ThemeProvider>
        </Box>
    )
}

export default DeleteAccount;