import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./Context/authContext.js";
import { LocationProvider } from "./Context/locationContext.js";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="212499413904-nlk7gp0i6bnlungtq579otmd3lli9nm6.apps.googleusercontent.com">
    <LocationProvider>
      <AuthProvider>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </AuthProvider>
    </LocationProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
