import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const DeleteWindow = ({ handleClose, open, btnDelete }) => {
  return (
    <>
      {open === undefined ? null : (
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          sx={{ textAlign: "center", opacity: 0.5 }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${"assets/icon/BackgroundFade.svg"})`,
              borderRadius: "25px",
            }}
          >
            <DialogTitle
              sx={{
                fontFamily: "SegoeUi",
                fontWeight: "1000",
                margin: "10px",
                textTransform: "uppercase",
                fontSize: "2rem",
              }}
              color="whiteSmoke"
            >
              Do you really want to delete this event?
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{
                  fontFamily: "SegoeUi",
                  color: "whitesmoke",
                  fontWeight: "1000",
                  margin: "10px",
                  fontSize: "1.5rem",
                }}
              >
                The row selected will be delete.
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleClose}
                sx={{ borderRadius: "25px" }}
                color="palePink"
                variant="contained"
                disableElevation
              >
                <Typography
                  sx={{
                    fontFamily: "SegoeUi",
                    fontWeight: "1000",
                    textTransform: "capitalize",
                  }}
                  color="secondary"
                  variant="button"
                >
                  Cancel
                </Typography>
              </Button>
              <Button
                onClick={() => {btnDelete(); handleClose()}}
                sx={{ borderRadius: "25px" }}
                color="paleGreen"
                variant="contained"
                disableElevation
              >
                <Typography
                  sx={{
                    fontFamily: "SegoeUi",
                    fontWeight: "1000",
                    textTransform: "capitalize",
                  }}
                  color="secondary"
                  variant="button"
                >
                  Confirm
                </Typography>
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
    </>
  );
};
export default DeleteWindow;
